import { GoogleLogin } from '@react-oauth/google';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStoreContext } from '../states/RootStore';
import { observer } from 'mobx-react';
import ProfessionalTitle from './ProfessionalTitle';
import { Box, Typography } from '@mui/material';
import theme from '../lib/theme';
import { WavingHand as WavingHandIcon } from '@mui/icons-material';

export const Login = observer(() => {
  const styles = {
    container: {
      margin: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    welcome: {
      margin: `${theme.spacing(2)} 0`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    notes: {
      margin: `${theme.spacing(2)} 0`,
      maxWidth: '400px'
    },
    wavingHandIcon: {
      fontSize: 40,
      textAlign: 'center'
    }
  };
  const rootStore = useContext(RootStoreContext);
  const navigate = useNavigate();
  const handleSuccess = (response: any) => {
    const token = response.credential;
    // Save the token in the local storage
    rootStore.setToken(token);
    // Save also the token in the local storage, so we can retrieve it when users refresh the page
    localStorage.setItem('token', token);
    navigate('/');
  };
  const handleError = () => {
    // Show an error message to the user using a toast using material ui
    console.error('Impossible to login');
  };
  return (
    <div>
      <ProfessionalTitle title="Login" />
      <Box sx={styles.container}>
        <Box sx={styles.welcome}>
          <Typography variant="h3">¡Bienvenida/o!</Typography>
          <WavingHandIcon sx={styles.wavingHandIcon} />
        </Box>
        <Box sx={styles.notes}>
          <Typography variant="h6">
            Para usar las utilidades de gestión de ahorro y metas, debes de
            estar identificado con tu cuenta de Google / Gmail.
          </Typography>
        </Box>
        <Box sx={styles.notes}>
          <Typography variant="body2">
            Por favor, haz click en el botón de Google, e introduce tu
            identificantivo como lo haces normalmente. Una vez realizado con
            éxito, podrás acceder a las páginas restringidas.
          </Typography>
        </Box>
        <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
      </Box>
    </div>
  );
});
