import axios, { AxiosResponse } from 'axios';
import { getFullUrl } from '../../core/lib/helper';
import { RootStore } from '../../core/states/RootStore';

export class GoalsTransporteLayer {
  fullUrl;
  store: RootStore | null = null;
  constructor(store: RootStore) {
    this.store = store;
    this.fullUrl = getFullUrl();
  }
  async getPersonalGoals(): Promise<any> {
    let response: AxiosResponse<any> | undefined = undefined;
    try {
      response = await axios.get(`${this.fullUrl}/api/personalGoals`, {
        headers: { Authorization: `Bearer ${this.store?.token}` }
      });
    } catch (error) {
      console.error('Error getting personal goals', error);
    }

    return response?.data || [];
  }

  async savePersonalGoals(goals) {
    let response: AxiosResponse<any> | undefined = undefined;
    try {
      response = await axios.post(`${this.fullUrl}/api/personalGoals`, goals, {
        headers: { Authorization: `Bearer ${this.store?.token}` }
      });
    } catch (error) {
      console.error('Error saving personal goals', error);
    }
    return response;
  }
}
