import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export class ConfigurationStore {
  transportLayer;
  isLoading;
  isSaving;
  isModified;
  configuration: Configuration | null = null;
  constructor(transportLayer) {
    makeAutoObservable(this, {
      configuration: observable,
      isLoading: observable,
      isSaving: observable,
      isModified: observable,
      loadConfiguration: action,
      createConfiguration: action,
      updateConfiguration: action
    });
    this.isLoading = false;
    this.isSaving = false;
    this.isModified = false;
    this.transportLayer = transportLayer;
    this.configuration = null;
  }

  async loadConfiguration() {
    this.isLoading = true;
    const response = await this.transportLayer.getConfiguration();
    runInAction(() => {
      // Check if response has an id
      if (response?.id) {
        this.updateConfigurationFromServer(response);
      }
      this.isLoading = false;
      this.isModified = false;
    });
  }

  updateConfigurationFromServer(json) {
    if (this.configuration) {
      this.configuration.updateFromJson(json);
    } else {
      this.configuration = new Configuration(this, json.id);
      this.configuration.updateFromJson(json);
    }
  }

  async createConfiguration() {
    const configuration = new Configuration(this, uuidv4());
    configuration.dateStartRange = new Date();
    configuration.dateEndRange = new Date();
    configuration.useEstimatedRecurringExpenses = true;
    configuration.useNonEstimatedRecurringExpenses = true;
    configuration.grossSalary = 0;
    configuration.mortgageYears = 0;
    configuration.mortgageMonths = 0;
    configuration.mortgageInterest = 0;
    this.configuration = configuration;
    this.isModified = true;
    return configuration;
  }

  async updateConfiguration() {
    this.isSaving = true;
    await this.transportLayer.saveConfiguration(this.configuration?.toJson);
    this.isSaving = false;
    this.isModified = false;
  }
}

export class Configuration {
  store: ConfigurationStore;
  id: string;
  userId: number = -1;
  dateStartRange: Date | undefined = new Date();
  dateEndRange: Date | undefined = new Date();
  useEstimatedRecurringExpenses: boolean = true;
  useNonEstimatedRecurringExpenses: boolean = true;
  grossSalary: number = 0;
  mortgageYears: number = 0;
  mortgageMonths: number = 0;
  mortgageInterest: number = 0;

  constructor(store: ConfigurationStore, id = '') {
    makeAutoObservable(this, {
      store: true,
      updateFromJson: action,
      toJson: computed,
      id: observable,
      userId: observable,
      dateStartRange: observable,
      dateEndRange: observable,
      useEstimatedRecurringExpenses: observable,
      useNonEstimatedRecurringExpenses: observable,
      grossSalary: observable,
      mortgageYears: observable,
      mortgageMonths: observable,
      mortgageInterest: observable
    });
    this.store = store;
    this.id = id;
  }

  setDateStartRange(date) {
    this.store.isModified = true;
    this.dateStartRange = date;
  }

  setDateEndRange(date) {
    this.store.isModified = true;
    this.dateEndRange = date;
  }

  setUseEstimatedRecurringExpenses(use) {
    this.store.isModified = true;
    this.useEstimatedRecurringExpenses = use;
  }

  setUseNonEstimatedRecurringExpenses(use) {
    this.store.isModified = true;
    this.useNonEstimatedRecurringExpenses = use;
  }

  setGrossSalary(salary) {
    this.store.isModified = true;
    this.grossSalary = salary;
  }

  setMortgageYears(years) {
    this.store.isModified = true;
    this.mortgageYears = years;
  }

  setMortgageMonths(months) {
    this.store.isModified = true;
    this.mortgageMonths = months;
  }

  setMortgageInterest(interest) {
    this.store.isModified = true;
    this.mortgageInterest = interest;
  }

  updateFromJson(json) {
    this.id = json.id;
    this.userId = json.userId;
    this.dateStartRange = json.dateStartRange;
    this.dateEndRange = json.dateEndRange;
    this.useEstimatedRecurringExpenses = json.useEstimatedRecurringExpenses;
    this.useNonEstimatedRecurringExpenses =
      json.useNonEstimatedRecurringExpenses;
    this.grossSalary = json.grossSalary;
    this.mortgageYears = json.mortgageYears;
    this.mortgageMonths = json.mortgageMonths;
    this.mortgageInterest = json.mortgageInterest;
  }

  get toJson() {
    return {
      id: this.id,
      userId: this.userId,
      dateStartRange: this.dateStartRange,
      dateEndRange: this.dateEndRange,
      useEstimatedRecurringExpenses: this.useEstimatedRecurringExpenses,
      useNonEstimatedRecurringExpenses: this.useNonEstimatedRecurringExpenses,
      grossSalary: this.grossSalary,
      mortgageYears: this.mortgageYears,
      mortgageMonths: this.mortgageMonths,
      mortgageInterest: this.mortgageInterest
    };
  }
}
