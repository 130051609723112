import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import './index.css';
import Layout from './modules/core/components/Layout';
import SalaryCalculator from './modules/salaryCalculator/components/SalaryCalculator';
import MortgageCalculator from './modules/mortgageCalculator/components/MortgageCalculator';
import Main from './modules/main/components/Main';
import { Login } from './modules/core/components/Login';
import { Logout } from './modules/core/components/Logout';
import { PrivateRoute } from './modules/core/components/PrivateRoute';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { getFullUrl } from './modules/core/lib/helper';
import DailyExpenses from './modules/personalExpenses/components/DailyExpenses';
import { observer } from 'mobx-react';
import { RootStoreContext } from './modules/core/states/RootStore';
import RecurringExpenses from './modules/personalExpenses/components/RecurringExpenses';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import PersonalGoals from './modules/personalGoals/components/PersonalGoals';

const App = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const [googleClientId, setGoogleClientId] = React.useState('');
  const fullUrl = getFullUrl();
  const token = rootStore.token;

  // If we can't get the token from local storage, redirect to login page
  React.useEffect(() => {
    if (window.location.pathname === '/login') {
      return;
    }
    if (!token) {
      const tokenStorage = localStorage.getItem('token');
      if (tokenStorage) {
        rootStore.setToken(tokenStorage);
      } else {
        window.location.href = '/login';
      }
    }
  });

  React.useEffect(() => {
    try {
      axios.get(`${fullUrl}/api/common/google`).then((response) => {
        setGoogleClientId(response.data.clientId);
      });
    } catch (error) {
      console.error('Error getting google client id');
      rootStore.setToken('');
      localStorage.removeItem('token');
    }
  }, [fullUrl]);

  if (!googleClientId) {
    return <div>Cargando...</div>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Main />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/calculadora-sueldo" element={<SalaryCalculator />} />
            <Route
              path="/calculadora-hipoteca"
              element={<MortgageCalculator />}
            />
            <Route
              path="/gastos-personales"
              element={
                <PrivateRoute>
                  <DailyExpenses />
                </PrivateRoute>
              }
            />
            <Route
              path="/gastos-recurrentes"
              element={
                <PrivateRoute>
                  <RecurringExpenses />
                </PrivateRoute>
              }
            />
            <Route
              path="/metas-personales"
              element={
                <PrivateRoute>
                  <PersonalGoals />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </GoogleOAuthProvider>
    </LocalizationProvider>
  );
});

export default App;
