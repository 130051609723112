const ExcelJS = require('exceljs');

const columnsList: ColumnDataFileType[] = [
  {
    dbName: 'concept',
    names: ['Concepto', 'Nombre']
  },
  {
    dbName: 'cost',
    names: ['Importe', 'Cantidad']
  },
  {
    dbName: 'date',
    names: ['Fecha Operación', 'Fecha Valor', 'Fecha', 'F.Valor']
  }
];

type ColumnDataFileType = {
  dbName: string;
  names: string[];
};

type ColumnPositionType = {
  column: number;
  row: number;
  key: string;
};

export const handleImportFile = (
  event: React.ChangeEvent<HTMLInputElement>
): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    const file = event.target.files?.[0];
    if (!file) {
      reject(new Error('Se debe seleccionar un archivo'));
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const buffer = e.target?.result as ArrayBuffer;
        await workbook.xlsx.load(buffer);

        const worksheet = workbook.worksheets[0];

        const columnsPositions: ColumnPositionType[] = [];
        // Find all columns and store their positions (column and row)
        worksheet.eachRow((row, rowNumber) => {
          columnsList.forEach((columnData, index) => {
            const columnPosition = row.values?.findIndex((value) => {
              return columnData.names.includes(value);
            });
            if (columnPosition !== -1) {
              columnsPositions.push({
                column: columnPosition,
                row: rowNumber,
                key: columnData.dbName
              });
            }
          });
        });

        // Go through every row of the spreadsheet and get the
        // values for each column And use as key the corresponding
        // dbName value
        const rows: any[] = [];
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > columnsPositions[0]?.row) {
            const rowData = columnsPositions.reduce((acc, column) => {
              acc[column.key] = row.getCell(column.column).value;
              return acc;
            }, {});
            rows.push(rowData);
          }
        });

        resolve(rows);
      } catch (error) {
        reject(new Error('Hubo un error al leer el archivo xls'));
      }
    };

    reader.onerror = () => {
      reject(
        new Error('Error al leer el archivo. Posiblemente por el formato')
      );
    };

    reader.readAsArrayBuffer(file);
  });
};
