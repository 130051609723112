import axios, { AxiosResponse } from 'axios';
import { getFullUrl } from '../../core/lib/helper';
import { RootStore } from '../../core/states/RootStore';

export class ConfigurationTransportLayer {
  fullUrl;
  store: RootStore | null = null;
  constructor(store: RootStore) {
    this.store = store;
    this.fullUrl = getFullUrl();
  }
  async getConfiguration(): Promise<any> {
    let response: AxiosResponse<any> | undefined = undefined;
    try {
      response = await axios.get(`${this.fullUrl}/api/configuration`, {
        headers: {
          Authorization: `Bearer ${this.store?.token}`
        }
      });
    } catch (error) {
      console.error('Error getting configuration', error);
    }
    return response?.data;
  }
  async saveConfiguration(configuration) {
    let response: AxiosResponse<any> | undefined = undefined;
    try {
      response = await axios.put(
        `${this.fullUrl}/api/configuration`,
        configuration,
        {
          headers: {
            Authorization: `Bearer ${this.store?.token}`
          }
        }
      );
    } catch (error) {
      console.error('Error saving configuration', error);
    }
    return response;
  }
}
