import React from 'react';
import { DailyExpensesStore } from '../../personalExpenses/states/DailyExpensesStore';
import { RecurringExpensesStore } from '../../personalExpenses/states/RecurringExpensesStore';
import { ExpensesTransportLayer } from '../../personalExpenses/states/ExpensesTransportLayer';
import { GoalsTransporteLayer } from '../../personalGoals/states/GoalsTransporteLayer';
import { ConfigurationTransportLayer } from '../../personalGoals/states/ConfigurationTransportLayer';
import { makeAutoObservable } from 'mobx';
import { PersonalGoalsStore } from '../../personalGoals/states/PersonalGoalsStore';
import { ConfigurationStore } from '../../personalGoals/states/ConfigurationStore';

export class RootStore {
  dailyExpensesStore: DailyExpensesStore;
  recurringExpensesStore: RecurringExpensesStore;
  personalGoalsStore: PersonalGoalsStore;
  configurationStore: ConfigurationStore;
  token: string = '';
  constructor() {
    makeAutoObservable(this);
    this.dailyExpensesStore = new DailyExpensesStore(
      new ExpensesTransportLayer(this)
    );
    this.recurringExpensesStore = new RecurringExpensesStore(
      new ExpensesTransportLayer(this)
    );
    this.personalGoalsStore = new PersonalGoalsStore(
      new GoalsTransporteLayer(this)
    );
    this.configurationStore = new ConfigurationStore(
      new ConfigurationTransportLayer(this)
    );
  }

  setToken(token: string) {
    this.token = token;
  }

  clearToken() {
    this.token = '';
  }

  isLoading() {
    return (
      this.dailyExpensesStore.isLoading ||
      this.dailyExpensesStore.isLoadingCategories ||
      this.recurringExpensesStore.isLoading ||
      this.recurringExpensesStore.isLoadingCategories ||
      this.personalGoalsStore.isLoading ||
      this.configurationStore.isLoading
    );
  }

  isSaving() {
    return (
      this.dailyExpensesStore.isSaving ||
      this.recurringExpensesStore.isSaving ||
      this.personalGoalsStore.isSaving ||
      this.configurationStore.isSaving
    );
  }
}

export const rootStore = new RootStore();
export const RootStoreContext = React.createContext(rootStore);
