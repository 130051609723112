import { Periodicity } from '@prisma/client';
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction
} from 'mobx';
import { Category } from './DailyExpensesStore';
import { v4 as uuidv4 } from 'uuid';
import { getDaysBetweenDates } from '../../personalGoals/lib/helpers';

// Extend RecurringExpense to include date, total.
export type RecurringExpenseExtended = {
  id: string;
  concept: string;
  cost: number;
  periodicity: Periodicity;
  date: Date;
  total: number;
};

export class RecurringExpensesStore {
  transportLayer;
  isLoading;
  isLoadingCategories;
  isSaving;
  isModified;
  categories: Category[];
  recurringExpenses: RecurringExpense[];

  constructor(transportLayer) {
    makeAutoObservable(this, {
      categories: observable,
      recurringExpenses: observable,
      isLoading: observable,
      isLoadingCategories: observable,
      isSaving: observable,
      isModified: observable,
      loadRecurringExpenses: action,
      updateRecurringExpenseFromServer: action,
      createRecurringExpense: action,
      loadCategories: action,
      updateCategoryFromServer: action,
      updateRecurringExpenses: action
    });
    this.isLoading = false;
    this.isLoadingCategories = false;
    this.isSaving = false;
    this.isModified = false;
    this.categories = [];
    this.recurringExpenses = [];
    this.transportLayer = transportLayer;
  }

  async loadRecurringExpenses() {
    this.isLoading = true;
    try {
      // Make sure categories are loaded before recurring expenses
      await this.loadCategories();

      // Get data from api
      const response = await this.transportLayer.getRecurringExpenses();
      // Store ids to check the records that might be missing
      const serverIds = new Set(response.map((gasto) => gasto.id));

      runInAction(() => {
        // Add or update existing records
        response.forEach((gasto) =>
          this.updateRecurringExpenseFromServer(gasto)
        );

        // Delete records that are not in the api anymore
        this.recurringExpenses = this.recurringExpenses.filter((gasto) =>
          serverIds.has(gasto.id)
        );

        this.isModified = false;
      });
    } catch (error) {
      console.error('Error al cargar los ingresos recurrentes:', error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  filterThisMonthRecurringExpenses(months = 1): RecurringExpenseExtended[] {
    if (months < 1 || months > 12) {
      throw new Error("El parámetro 'months' debe estar entre 1 y 12.");
    }

    const expenseExtended: {
      id: string;
      concept: string;
      cost: number;
      periodicity: Periodicity;
      date: Date;
      total: number;
    }[] = [];

    const today = new Date();

    // First day of the target month
    const targetMonth = new Date(
      today.getFullYear(),
      today.getMonth() + months - 1,
      1
    );

    const targetMonthWhenMonthFirst = new Date(
      today.getFullYear(),
      today.getMonth() + 1 - 1,
      1
    );

    // Last day of the target month
    const endOfTargetMonth = new Date(
      today.getFullYear(),
      today.getMonth() + months,
      0
    );

    const daysInMonth = (date: Date) =>
      new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    // Find out if a month is multiple of the period
    const isMatchingPeriod = (
      from: Date | undefined,
      periodicity: Periodicity,
      targetMonth: number,
      targetYear: number
    ): boolean => {
      if (!from) return false;

      const fromDate = new Date(from);
      const fromYear = fromDate.getFullYear();
      const fromMonthIndex = fromDate.getMonth(); // 0-based month

      const diffYears = targetYear - fromYear;
      const diffMonths = targetMonth - fromMonthIndex + diffYears * 12;

      switch (periodicity) {
        case Periodicity.BIMENSUAL:
          return diffMonths % 2 === 0;
        case Periodicity.TRIMESTRAL:
          return diffMonths % 3 === 0;
        case Periodicity.SEMESTRAL:
          return diffMonths % 6 === 0;
        case Periodicity.ANUAL:
          return diffMonths % 12 === 0;
        default:
          return true; // Always true for DIARIO, SEMANAL, QUINCENAL, MENSUAL
      }
    };

    for (const expense of this.recurringExpenses.filter(
      (gasto) => !gasto.toDelete
    )) {
      const { periodicity, from, to, cost } = expense;

      let newCost = cost;

      // When periodicity is bimensual, trimensual, semestral, anual
      // and the expense from is null
      // we should use the target month as the from date.
      // But only when months is 1. When months is 2, that's the second month,
      // When bimensual, trimensual, semestral, anual expenses are not going to happen
      // And we need to exclude them, so we use the first month as the from date.
      const defaultFrom =
        months === 2 ? targetMonthWhenMonthFirst : targetMonth;
      const includeExpense = isMatchingPeriod(
        from ?? defaultFrom,
        periodicity,
        targetMonth.getMonth(),
        targetMonth.getFullYear()
      );

      if (includeExpense) {
        let total = 0;
        const monthDays = daysInMonth(targetMonth);
        let expenseFrom = from ? new Date(from) : targetMonth;
        let expenseTo = to ? new Date(to) : endOfTargetMonth;
        if (from && expenseFrom < targetMonth) {
          expenseFrom = targetMonth;
        }
        if (to && expenseTo > endOfTargetMonth) {
          expenseTo = endOfTargetMonth;
        }
        const includeEndDaySeconds = true;
        const totalEffectiveDays = getDaysBetweenDates(
          expenseFrom,
          expenseTo,
          includeEndDaySeconds
        );
        const ratio = totalEffectiveDays / monthDays;

        switch (periodicity) {
          case Periodicity.DIARIO:
            total = cost * totalEffectiveDays;
            break;
          case Periodicity.SEMANAL:
            total = cost * 4 * ratio; // 4 times a month
            break;
          case Periodicity.QUINCENAL:
            total = cost * 2 * ratio; // 2 times in a month
            break;
          case Periodicity.MENSUAL:
            total = totalEffectiveDays === monthDays ? cost : cost * ratio; // 1 time per month
            break;
          case Periodicity.BIMENSUAL:
          case Periodicity.TRIMESTRAL:
          case Periodicity.SEMESTRAL:
          case Periodicity.ANUAL:
            total = cost; // 1 time per month
            break;
        }

        expenseExtended.push({
          id: expense.id,
          concept: expense.concept,
          cost: newCost,
          periodicity: expense.periodicity,
          date: new Date(targetMonth.getFullYear(), targetMonth.getMonth(), 1),
          total
        });
      }
    }
    return expenseExtended;
  }

  async updateRecurringExpenseFromServer(json) {
    let recurringExpense = this.recurringExpenses.find(
      (gasto) => gasto.id === json.id
    );
    if (!recurringExpense) {
      recurringExpense = new RecurringExpense(this, json.id);
      this.recurringExpenses.push(recurringExpense);
    }
    recurringExpense.updateFromJson(json);
  }

  async createRecurringExpense() {
    const recurringExpense = new RecurringExpense(this, uuidv4());
    recurringExpense.concept = 'Nombre';
    recurringExpense.cost = 0;
    recurringExpense.from = new Date();
    recurringExpense.periodicity = Periodicity.MENSUAL;
    this.recurringExpenses.push(recurringExpense);
    this.isModified = true;
    return recurringExpense;
  }

  async loadCategories() {
    this.isLoadingCategories = true;
    const response = await this.transportLayer.getCategories();
    runInAction(() => {
      response?.forEach((categoryJson) =>
        this.updateCategoryFromServer(categoryJson)
      );
      this.isLoadingCategories = false;
    });
  }

  updateCategoryFromServer(json) {
    let category = this.categories.find((category) => category.id === json.id);
    if (!category) {
      category = new Category(this, json.id);
      this.categories.push(category);
    }
    category.updateFromJson(json);
  }

  async updateRecurringExpenses() {
    this.isSaving = true;
    await this.transportLayer.saveRecurringExpenses(
      this.recurringExpenses.map((gasto) => gasto.toJson)
    );
    this.isSaving = false;
    this.isModified = false;
  }

  get toJsonCSV() {
    return this.recurringExpenses
      .filter((gasto) => !gasto.toDelete)
      .map((gasto) => gasto.toJsonCSV);
  }
}

// export type Periodicity = 'diario' | 'mensual' | 'bimestral' | 'trimestral' | 'semestral' | 'anual';
/*export type PeriodicityDays = 1 | 30 | 60 | 90 | 180 | 365;

// Create a mapping of periodicity to days
export const periodicityToDays = {
  diario: 1,
  mensual: 30,
  bimestral: 60,
  trimestral: 90,
  semestral: 180,
  anual: 365
};*/

export class RecurringExpense {
  store: RecurringExpensesStore;
  id: string;
  userId: number = -1;
  category: Category;
  concept: string = '';
  cost: number = 0;
  from: Date | undefined = new Date();
  to: Date | undefined = new Date();
  periodicity: Periodicity = Periodicity.MENSUAL;
  estimation: boolean = true;
  regexDeletion: string = '';
  isNew: boolean = true;
  toDelete: boolean = false;
  isExpense: boolean = true;

  constructor(store: RecurringExpensesStore, id = '') {
    makeAutoObservable(this, {
      store: true,
      updateFromJson: action,
      delete: action,
      toJson: computed,
      id: observable,
      userId: observable,
      category: observable,
      concept: observable,
      cost: observable,
      from: observable,
      to: observable,
      periodicity: observable,
      estimation: observable,
      regexDeletion: observable,
      isNew: observable,
      isExpense: observable
    });
    this.store = store;
    this.id = id;
    this.category = new Category(this.store, store.categories[0]?.id);
  }

  setCategory(category) {
    this.category = category;
  }

  setConcept(concept) {
    this.store.isModified = true;
    this.concept = concept;
  }

  setCost(cost) {
    this.store.isModified = true;
    if (cost < 0) {
      this.isExpense = true;
    } else {
      this.isExpense = false;
    }
    this.cost = cost;
  }

  setFrom(from) {
    this.store.isModified = true;
    this.from = from;
  }

  setTo(to) {
    this.store.isModified = true;
    this.to = to;
  }

  setPeriodicity(periodicity) {
    this.store.isModified = true;
    this.periodicity = periodicity;
  }

  setEstimation(estimation) {
    this.store.isModified = true;
    this.estimation = estimation;
  }

  setRegexDeletion(regexDeletion) {
    this.store.isModified = true;
    this.regexDeletion = regexDeletion;
  }

  setIsExpense(isExpense) {
    this.store.isModified = true;
    this.isExpense = isExpense;
    if (this.isExpense) {
      this.cost = Math.abs(this.cost) * -1;
    } else {
      this.cost = Math.abs(this.cost);
    }
  }

  updateFromJson(json) {
    this.id = json.id;
    this.userId = json.userId;
    this.concept = json.concept;
    this.cost = json.cost;
    this.from = json.from;
    this.to = json.to;
    this.periodicity = json.periodicity;
    this.estimation = json.estimation;
    this.regexDeletion = json.regexDeletion;
    this.isNew = false;
    this.toDelete = false;
    const category = this.store.categories.find(
      (category) => category.id === json.categoryId
    );
    if (category) {
      this.category = category;
    }
    this.isExpense = json.isExpense;
  }

  delete() {
    this.store.isModified = true;
    this.toDelete = true;
  }

  get toJson() {
    return {
      id: this.id,
      userId: this.userId,
      categoryId: this.category.id,
      concept: this.concept,
      cost: this.cost,
      from: this.from,
      to: this.to,
      periodicity: this.periodicity,
      estimation: this.estimation,
      regexDeletion: this.regexDeletion,
      isNew: this.isNew,
      toDelete: this.toDelete,
      isExpense: this.isExpense
    };
  }

  get toJsonCSV() {
    return {
      concept: this.concept,
      cost: this.cost,
      from: this.from,
      to: this.to,
      periodicity: this.periodicity,
      estimation: this.estimation,
      category: this.category.name
    };
  }
}
