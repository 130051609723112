import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { rootStore, RootStoreContext } from './modules/core/states/RootStore';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './modules/core/lib/theme';

import { configure } from 'mobx';

configure({
  enforceActions: 'never'
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Asignamos store a window, para poder hacer debug en el navegador
// if (process.env.NODE_ENV === 'development') {
(window as any).store = rootStore;
// }
root.render(
  <React.StrictMode>
    <RootStoreContext.Provider value={rootStore}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </RootStoreContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
