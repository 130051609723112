import { Box, Typography, useTheme } from '@mui/material';
import FamilyEconomicsImg from '../../../lib/images/family-economics.jpeg';
import MortgageCalculatorImg from '../../../lib/images/mortgage-calculator.jpeg';
import React from 'react';
import shadows from '@mui/material/styles/shadows';

const Main = () => {
  const theme = useTheme();
  const styles = {
    main: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      },
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      margin: theme.spacing(4)
    },
    section: {
      flex: 1, // Same space for all childs
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(1),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      boxShadow: shadows[3],
      backgroundColor: theme.palette.background.paper
    },
    img: {
      alignSelf: 'center',
      maxWidth: '45%',
      paddingBottom: theme.spacing(3)
    },
    title: {
      marginBottom: theme.spacing(3)
    }
  };
  return (
    <Box sx={styles.main}>
      <Box sx={styles.section}>
        <Typography variant="h2" style={styles.title}>
          Gestión de gastos familiares
        </Typography>
        <img
          src={FamilyEconomicsImg}
          alt="Imagen representando a una familia haciendo cálculos"
          style={styles.img}
        />
        <p>
          Esta aplicación web permite gestionar todos los gastos e ingresos
          diarios. Podrás tanto importar los movimientos de tu cuenta bancaria,
          como introducirlos manualmente en la aplicación. También se pueden
          gestionar los gastos e ingresos recurrentes, para que nunca más se te
          olviden los siguientes pagos que debes de realizar, y no te quedes con
          una cuenta en negativo. Todos esta información, será crucial para
          poder ofrecerte un servicio realmente útil. El poder tener metas de
          ahorro, y calcular cuando llegará el día que cumplas dicha meta.
        </p>
      </Box>
      <Box sx={styles.section}>
        <Typography variant="h2" style={styles.title}>
          Calculadoras
        </Typography>
        <img
          src={MortgageCalculatorImg}
          alt="Imagen representando a una pareja utilizando una calculadora de hipotecas"
          style={styles.img}
        />
        <p>
          Además de todos los servicios que requieren que estés identificado en
          nuestro sistema, también ofrecemos dos calculadores muy útiles, que
          podrás utilizar sin necesidad de identificarte.
        </p>
        <p>
          Comienza ya a calcular la cuota mensual de la hipoteca de tu próxima
          vivienda, con nuestra calculadora de hipotecas. Únicamente necesitarás
          unos solos segundos para rellenar los datos necesarios.
        </p>
        <p>
          Y también te ofrecemos una calculadora para calcular tu sueldo neto.
          Prácticamente ninguna calculadora de internet te va a ofrecer la
          posibilidad de seleccionar tu comunidad, y de configurar tu situación
          personal, y ofrecerte unos resultados tan fidedignos como los que te
          mostramos en nuestra calculadora.
        </p>
      </Box>
    </Box>
  );
};

export default Main;
