import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStoreContext } from '../states/RootStore';

export const Logout = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Delete token from store and localStorage
    rootStore.clearToken();
    localStorage.removeItem('token');
    // Redirect user to main page
    navigate('/');
  }, [navigate]);

  return <div>Please wait...</div>;
});
