import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import theme from '../lib/theme';

const CircularProgressComponent = ({ message }: { message: string }) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  };
  return (
    <Box sx={styles.container}>
      <Typography variant="body2" color="textSecondary">
        {message}
      </Typography>
      <CircularProgress color="success" />
    </Box>
  );
};

const Progress = ({
  isVisible,
  message,
  fullScreen = true
}: {
  isVisible: boolean;
  message: string;
  fullScreen?: boolean;
}) => {
  const styles = {
    backdrop: {
      color: '#fff',
      zIndex: theme.zIndex.drawer + 1
    }
  };
  if (!isVisible) return <></>;
  return (
    (fullScreen && (
      <Backdrop sx={styles.backdrop} open={isVisible}>
        <CircularProgressComponent message={message} />
      </Backdrop>
    )) || <CircularProgressComponent message={message} />
  );
};

export default Progress;
