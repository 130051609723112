import React, { useState } from 'react';
import ProfessionalTitle from '../../core/components/ProfessionalTitle';
import {
  Box,
  FormControl,
  Grid2 as Grid,
  TextField,
  Typography
} from '@mui/material';
import theme from '../../core/lib/theme';
import {
  handleCommaFloatInputChange,
  preventInvalidInput
} from '../../core/lib/helper';

const MortgageCalculator = () => {
  const [capitalPendiente, setCapitalPendiente] = useState(0);
  const [plazoAmortizacion, setPlazoAmortizacion] = useState(0); // Years
  const [interes, setInteres] = useState<number>(0); // Percentage
  const [interesUI, setInteresUI] = useState<string>('0,00');

  const MESES_EN_ANO = 12;

  const styles = {
    main: {
      height: '100vh',
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'transparent',
        height: 'auto'
      }
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%'
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '90%',
        flexDirection: 'row'
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '80%'
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: '75%'
      },
      margin: '0 auto'
    },
    formContainer: {
      width: '100%',
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.background.paper,
        margin: '0 10px',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3]
      }
    },
    formControl: {
      margin: `${theme.spacing(2)} 0`
    },
    formInput: {
      width: '100%',
      fontSize: '16px',
      color: '#4a5568'
    },
    resultLabel: {
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    resultNumber: {
      fontSize: '1.2rem'
    }
  };

  const calcularCuotaMensual = (
    capitalPendiente,
    interes,
    plazoAmortizacion
  ) => {
    const interestBy100 = interes / 100;
    // Ejemplo: 147000×((((1+0.029÷12)^(30×12))×(0.029÷12))÷((1+0.029÷12)^(30×12)−1))
    const cuota =
      capitalPendiente *
        (((1 + interestBy100 / MESES_EN_ANO) **
          (plazoAmortizacion * MESES_EN_ANO) *
          (interestBy100 / MESES_EN_ANO)) /
          ((1 + interestBy100 / MESES_EN_ANO) **
            (plazoAmortizacion * MESES_EN_ANO) -
            1)) || 0;
    return cuota;
  };

  return (
    <Grid sx={styles.main}>
      <ProfessionalTitle title="Calculadora de hipoteca" />
      <Grid sx={styles.container}>
        <Box sx={styles.formContainer}>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              fullWidth
              label="Capital pendiente a amortizar o cantidad a hipotecar"
              type="number"
              sx={styles.formInput}
              value={capitalPendiente}
              onBeforeInput={preventInvalidInput}
              onChange={(e) => setCapitalPendiente(Number(e.target.value))}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              fullWidth
              label="Plazo en años"
              type="number"
              sx={styles.formInput}
              value={plazoAmortizacion}
              onBeforeInput={preventInvalidInput}
              onChange={(e) => setPlazoAmortizacion(Number(e.target.value))}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              fullWidth
              label="Interés anual"
              type="text"
              placeholder="0,00"
              sx={styles.formInput}
              value={interesUI}
              inputProps={{
                inputMode: 'decimal'
              }}
              onChange={(e) =>
                handleCommaFloatInputChange(e, setInteresUI, setInteres)
              }
            />
          </FormControl>

          <Box>
            <Typography variant="h6" style={styles.resultLabel}>
              Cuota mensual
            </Typography>
            <Typography variant="h5" style={styles.resultNumber}>
              {calcularCuotaMensual(
                capitalPendiente,
                interes,
                plazoAmortizacion
              ).toLocaleString('es-ES', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
              €
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MortgageCalculator;
