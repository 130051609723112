import React from 'react';
import irpfComunidades from '../datos/irpfComunidades.json';
import minimosPersonales from '../datos/minimosPersonales.json';
import gastos from '../datos/gastos.json';
import ssDatos from '../datos/seguridadSocial.json';
import ssCategorias from '../datos/seguridadSocialCategorias.json';
import ProfessionalTitle from '../../core/components/ProfessionalTitle';
import {
  Box,
  Button,
  Select,
  TextField,
  Typography,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Grid2 as Grid,
  Alert
} from '@mui/material';
import theme from '../../core/lib/theme';
import SalaryDetails from './SalaryDetails';

const FAMILIA_NUMEROSA_HIJOS_MIN = 3;
const MINIMO_SUELDO_BRUTO = 1;
const MINIMO_RETENCION_IRPF = 15000;
const YEAR_DEFAULT = '2025';
const COMMUNITY_DEFAULT = 'Madrid';
const AGE_CONTRIBUTOR_DEFAULT = 60;
export const SS_PROFESSIONAL_CATEGORY_DEFAULT = 2;

export type DatosFormulario = {
  grossAnnual: number;
  year: string;
  community: string;
  jointTaxReturn: boolean;
  dataMinimums: DataMinimums;
  categoriaProfesional: number;
};

type DataMinimums = {
  taxPayer: {
    age: number;
  };
  descendants: {
    menores25: number;
    menores3: number;
    death: number;
  };
  ascendants: {
    over65: number;
    over75: number;
    discapacitados: number;
    death: number;
  };
  specialDisability: {
    '>33': number;
    '>65': number;
    necesitaAyudaTerceros: number;
  };
};

type MinimoDatosJson = {
  reduccionesBaseImponible: {
    familiaNumerosa?: {
      '=3': number;
      extra: number;
    };
  };
  taxPayer: {
    general: number;
    extra: {
      '>': {
        '65': number;
        '75': number;
      };
    };
  };
  descendants: {
    general: {
      '=': {
        '1': number;
        '2': number;
        '3': number;
      };
      '>=': {
        '4': number;
      };
    };
    extra: {
      '<': {
        '3': number;
      };
    };
    death: number;
  };
  ascendants: {
    general: {
      '>': {
        '65': number;
      };
    };
    extra: {
      '>': {
        '75': number;
      };
    };
    death: number;
  };
  disability: {
    general: {
      '>': {
        '33': number;
        '65': number;
      };
    };
    helpThirdParties: {
      extra: {
        '>': {
          '65': number;
        };
      };
    };
  };
};

const calcularDeducciones = (
  minimosDatosForm: DataMinimums,
  year,
  community,
  jointTaxReturn: boolean,
  restoBaseLiquidable?: number
) => {
  let minimoBaseLiquidable = 0;
  // Comprobar que el year y la community existen en el JSON y coger el valor
  const añoExiste = Object.keys(minimosPersonales).find(
    (key) => key === String(year)
  );
  let comunidadExiste = Object.keys(minimosPersonales[year]).find(
    (item) => item === community
  );
  // Cuando no hay mínimos para una community, utilizar los del Estado
  // Esto es así porque hay comunidades que no tienen mínimos y utilizan
  // los del Estado.
  if (!comunidadExiste) {
    community = 'Estado';
    comunidadExiste = Object.keys(minimosPersonales[year]).find(
      (item) => item === community
    );
  }
  if (!añoExiste || !comunidadExiste) {
    return minimoBaseLiquidable;
  }
  const minimos: MinimoDatosJson = minimosPersonales[year][community];
  if (minimos) {
    const { taxPayer, descendants, ascendants, disability } = minimos;
    const { general, extra } = taxPayer;
    const {
      general: descendientesGeneral,
      extra: descendientesExtras,
      death: descendientesFallecimiento
    } = descendants;
    const {
      general: ascendientesGeneral,
      extra: ascendientesExtras,
      death: fallecimientoAscendientes
    } = ascendants;
    const { general: discapacidadGeneral, helpThirdParties } = disability;
    // El general siempre se suma a todo el mundo
    minimoBaseLiquidable += general;
    // Recorrer operandos
    for (const operando in extra) {
      if (operando === '>') {
        // Recorrer todas las edades
        for (const age in extra[operando]) {
          const edadNum = Number(age);
          // Pasamos por todas las edades y vamos sumando si cumple
          // la condición de cada rango de age
          if (minimosDatosForm.taxPayer.age > edadNum) {
            minimoBaseLiquidable += extra[operando][age];
          }
        }
      }
      // TODO Añadir más operadores (Solo es necesario '>' por ahora)
    }
    // *** Apartado Descendientes ****
    // Recorrer todos los descendants en '=', sumar cada posición
    // si tiene mayor o igual número de hijos
    for (const operando in descendientesGeneral['=']) {
      if (minimosDatosForm.descendants.menores25 >= Number(operando)) {
        // Dividir entre 2 si la renta no es conjunta
        minimoBaseLiquidable += jointTaxReturn
          ? descendientesGeneral['='][operando]
          : descendientesGeneral['='][operando] / 2;
      }
    }
    if (minimosDatosForm.descendants.menores25 >= 4) {
      // Dividir entre 2 si la renta no es conjunta
      minimoBaseLiquidable += jointTaxReturn
        ? descendientesGeneral['>=']['4']
        : descendientesGeneral['>=']['4'] / 2;
    }

    if (minimosDatosForm.descendants.menores3 > 0) {
      // Dividir entre 2 si la renta no es conjunta
      minimoBaseLiquidable += jointTaxReturn
        ? descendientesExtras['<']['3']
        : descendientesExtras['<']['3'] / 2;
    }
    if (minimosDatosForm.descendants.death > 0) {
      // Dividir entre 2 si la renta no es conjunta
      minimoBaseLiquidable += jointTaxReturn
        ? descendientesFallecimiento
        : descendientesFallecimiento / 2;
    }
    // *** Apartado Ascendientes ***
    if (minimosDatosForm.ascendants.over65 > 0) {
      minimoBaseLiquidable +=
        ascendientesGeneral['>']['65'] * minimosDatosForm.ascendants.over65;
    }
    if (minimosDatosForm.ascendants.over75 > 0) {
      // Se suma al anterior de mayores de 65
      minimoBaseLiquidable +=
        ascendientesExtras['>']['75'] * minimosDatosForm.ascendants.over75;
    }
    if (minimosDatosForm.ascendants.death > 0) {
      minimoBaseLiquidable +=
        fallecimientoAscendientes * minimosDatosForm.ascendants.death;
    }
    // *** Discapacidad del taxPayer ***
    if (minimosDatosForm.specialDisability['>33'] > 0) {
      minimoBaseLiquidable += discapacidadGeneral['>']['33'];
    }
    if (minimosDatosForm.specialDisability['>65'] > 0) {
      minimoBaseLiquidable += discapacidadGeneral['>']['65'];
    }
    if (minimosDatosForm.specialDisability.necesitaAyudaTerceros > 0) {
      minimoBaseLiquidable += helpThirdParties.extra['>']['65'];
    }
    // TODO: Como mejora se podría introducir la disability
    // tanto para los descendants como para los ascendants

    // Comprobar que no pase el tope del rango (restoBaseLiquidable)
    // si lo hace, entonces tomar el valor del restoBaseLiquidable
    //if (minimoBaseLiquidable > restoBaseLiquidable) {
    // minimoBaseLiquidable = restoBaseLiquidable;
    //}
  }
  return minimoBaseLiquidable;
};

type SeguridadSocialDataType = {
  contigenciasComunes: number;
  formacionProfesional: number;
  desempleo: number;
  MEI: number;
  base: number;
  baseMaxima: number;
  baseMinima: {
    [key: string]: number;
  };
};

const calcularSeguridadSocialMensualYBase = (
  grossAnnual: number,
  year,
  categoriaProfesional: number
) => {
  const seguridadSocialAñoExiste = Object.keys(ssDatos).find(
    (key) => key === String(year)
  );
  let ssMensual = 0;
  let salarioMaximoParaCalculo = 0;
  if (seguridadSocialAñoExiste) {
    const seguridadSocialDatos: SeguridadSocialDataType = ssDatos[year];
    const minimumBaseForCategory =
      seguridadSocialDatos.baseMinima[categoriaProfesional.toString()];
    const salarioBrutoMensual = grossAnnual / 12;
    salarioMaximoParaCalculo =
      salarioBrutoMensual > seguridadSocialDatos.baseMaxima
        ? seguridadSocialDatos.baseMaxima
        : salarioBrutoMensual;
    salarioMaximoParaCalculo =
      salarioMaximoParaCalculo < minimumBaseForCategory
        ? minimumBaseForCategory
        : salarioMaximoParaCalculo;
    const ssSumaPorcentajes =
      seguridadSocialDatos.contigenciasComunes +
      seguridadSocialDatos.formacionProfesional +
      seguridadSocialDatos.desempleo +
      seguridadSocialDatos.MEI;
    ssMensual = salarioMaximoParaCalculo * (ssSumaPorcentajes / 100);
  }

  return {
    ssMensual,
    baseCotizacionSS: salarioMaximoParaCalculo
  };
};

const calcularReduccionesBaseImponible = (
  dataMinimums: DataMinimums,
  numHijos: number,
  community: string,
  year: string,
  jointTaxReturn: boolean
) => {
  let reduccionBaseImponible = 0;
  // Comprobar que el year y la community existen en el JSON y coger el valor
  const añoExiste = Object.keys(minimosPersonales).find(
    (key) => key === String(year)
  );
  let comunidadExiste = Object.keys(minimosPersonales[year]).find(
    (item) => item === community
  );
  if (!añoExiste || !comunidadExiste) {
    return reduccionBaseImponible;
  }
  const minimos: MinimoDatosJson = minimosPersonales[year][community];
  if (minimos) {
    const { reduccionesBaseImponible } = minimos;
    const { familiaNumerosa } = reduccionesBaseImponible;
    // *** Reducciones por familia numerosa ***
    if (numHijos >= FAMILIA_NUMEROSA_HIJOS_MIN && familiaNumerosa) {
      let reduccionBaseImponibleHijos = familiaNumerosa['=3'];
      // Por cada hijo que supere el mínimo, se suma el extra
      if (numHijos > FAMILIA_NUMEROSA_HIJOS_MIN) {
        reduccionBaseImponibleHijos +=
          familiaNumerosa.extra * (numHijos - FAMILIA_NUMEROSA_HIJOS_MIN);
      }
      // Dividir entre 2 si la renta no es conjunta
      if (!jointTaxReturn) {
        reduccionBaseImponibleHijos /= 2;
      }
      reduccionBaseImponible += reduccionBaseImponibleHijos;
    }
    // *** Reducciones por disability ***
    // Se introducido también la disability en el fichero de los minimos
    // personales, por si quiero poner cifras diferentes.
    // Pero por ahora, todas las comunidades tomarán los mismos valores
    // que son los del estado.
    const discapacitados = dataMinimums.specialDisability;
    if (discapacitados['>33']) {
      reduccionBaseImponible += gastos.disability['33'];
    }
    // TODO: Los cálculos finales son correctos, pero comprobar por qué
    // hay una diferencia de unos 250 euros en la base imponible con una
    // de las calculadoras de internet
    if (discapacitados['>65']) {
      reduccionBaseImponible += gastos.disability['65'];
    }
    if (discapacitados.necesitaAyudaTerceros) {
      reduccionBaseImponible += gastos.disability.helpThirdParties;
    }
  }
  return reduccionBaseImponible;
};

export type TramoType = {
  baseLiquidable: number;
  restoBaseLiquidable: number;
  restoBaseLiquidableActualizada?: number;
  tipoPorcentaje: number;
  impuestoSinMinimos: number;
  impuesto: number;
};

// Calcular base imponible o base liquidable general
const calcularBaseImponible = (
  grossAnnual: number,
  dataMinimums: DataMinimums,
  community: string,
  year: string,
  aplicarReduccion: boolean = false,
  jointTaxReturn: boolean = false,
  categoriaProfesional: number = SS_PROFESSIONAL_CATEGORY_DEFAULT
) => {
  const seguridadSocialMensual = calcularSeguridadSocialMensualYBase(
    grossAnnual,
    year,
    categoriaProfesional
  );
  const numHijos =
    dataMinimums.descendants.menores25 + dataMinimums.descendants.menores3;
  const reduccionesBaseImponible = calcularReduccionesBaseImponible(
    dataMinimums,
    numHijos,
    community,
    year,
    jointTaxReturn
  );
  const baseImponible =
    grossAnnual -
    (aplicarReduccion ? reduccionesBaseImponible : 0) -
    seguridadSocialMensual.ssMensual * 12 -
    gastos.OtrosGastosDeducibles;
  return baseImponible;
};

// Esta función es clave para poder calcular las cuotas
// según los tramos de cada community y del Estado.
// Sirve hacer las siguientes cuotas:
// Calcular cuota 1 y cuota 2 para las rentas del trabajo
// Calcular cuota 3 y cuota 4 para las deducciones
const calcularCuotaTramos = (
  brutoAnualODeducciones: number,
  year: string,
  community: string,
  esDeduccion: boolean,
  dataMinimums: DataMinimums,
  jointTaxReturn: boolean,
  categoriaProfesional: number = SS_PROFESSIONAL_CATEGORY_DEFAULT
) => {
  // Para las deducciones, tomamos el bruto anual, que en realidad no es el bruto anual,
  // si no las deducciones totales que se van a aplicar.
  // Si no estamos calculando los impuestos a pagar por las deducciones,
  // entonces calculamos la base imponible pasando el bruto anual.
  const baseImponibleOTotal = esDeduccion
    ? brutoAnualODeducciones
    : calcularBaseImponible(
        brutoAnualODeducciones,
        dataMinimums,
        community,
        year,
        true,
        jointTaxReturn,
        categoriaProfesional
      );
  let cuota = 0;
  // Comprobar que el year y la community existen en el JSON y coger el valor
  const irpfAñoExiste = Object.keys(irpfComunidades).find(
    (key) => key === String(year)
  );
  const irpfComunidadExiste = Object.keys(irpfComunidades[year]).find(
    (item) => item === community
  );

  let tramos: TramoType[] = [];
  if (irpfAñoExiste && irpfComunidadExiste) {
    const irpf = irpfComunidades[year][community];
    if (irpf) {
      cuota = irpf.reduce((acumulador, tramo) => {
        const { baseLiquidable, restoBaseLiquidable, tipoPorcentaje } = tramo;
        let restoBaseLiquidableActualizada = restoBaseLiquidable;

        // Actualizar baseLiquidable solo para el primer tramo
        if (acumulador === 0) {
          /*const primerTramoBaseLiquidable = calcularDeducciones(
            dataMinimums,
            year,
            community,
            restoBaseLiquidable
          );
          restoBaseLiquidableActualizada =
            restoBaseLiquidable - primerTramoBaseLiquidable;
            */
          restoBaseLiquidableActualizada = restoBaseLiquidable;
        }
        const maximo = Number(baseLiquidable) + Number(restoBaseLiquidable);
        // if (Number(baseImponible) > Number(maximo)) {
        if (
          Number(baseImponibleOTotal) > Number(maximo) &&
          Number(restoBaseLiquidable) > 0
        ) {
          const impuesto =
            (Number(restoBaseLiquidableActualizada) * tipoPorcentaje) / 100;
          const impuestoSinMinimos =
            (Number(restoBaseLiquidable) * tipoPorcentaje) / 100;
          tramos.push({
            baseLiquidable,
            restoBaseLiquidableActualizada: restoBaseLiquidableActualizada || 0,
            restoBaseLiquidable: restoBaseLiquidable,
            tipoPorcentaje,
            impuestoSinMinimos,
            impuesto
          });
          return acumulador + impuesto;
        } else {
          if (Number(baseImponibleOTotal) < Number(baseLiquidable)) {
            return acumulador;
          }
          const impuesto =
            ((Number(baseImponibleOTotal) - Number(baseLiquidable)) *
              tipoPorcentaje) /
            100;
          tramos.push({
            baseLiquidable,
            restoBaseLiquidable: restoBaseLiquidableActualizada || 0,
            tipoPorcentaje,
            impuestoSinMinimos: impuesto,
            impuesto
          });
          return acumulador + impuesto;
        }
      }, 0);
    }
  }

  if (cuota < 0) {
    // Si la cuota sale negativa, es porque las bonificaciones han hecho
    // que el impuesto a pagar sea negativo, pero la Agencia Tributaria
    // no nos va a pagar, así que ponemos la cuota a 0
    cuota = 0;
  }

  return {
    cuota, // Cuota1 y cuota2
    tramos,
    baseImponible: calcularBaseImponible(
      brutoAnualODeducciones,
      dataMinimums,
      community,
      year,
      false,
      jointTaxReturn,
      categoriaProfesional
    ), // Base imponible sin reducciones
    baseImponibleConReducciones: baseImponibleOTotal
  };
};

export type CalculoIRPFType = {
  cuotaComunidadAntesDeducciones: number;
  cuotaEstatalAntesDeducciones: number;
  cuotaComunidad: number;
  cuotaEstatal: number;
  cuotaTotalAntesDeducciones: number;
  cuotaTotal: number;
  sueldoNetoAnual: number;
  SueldoNetoMensual: number;
  ssMensual: number;
  baseCotizacionSS: number;
  tramosComunidad: TramoType[];
  tramosEstatal: TramoType[];
  baseImponible: number;
  baseImponibleConReducciones: number;
  cuotaDeduccionesComunidad: number;
  cuotaDeduccionesEstado: number;
  deduccionesComunidadAntesImpuestos: number;
  deduccionesEstadoAntesImpuestos: number;
};

export const calcularIRPF = ({
  grossAnnual,
  year,
  community,
  jointTaxReturn,
  dataMinimums,
  categoriaProfesional
}: DatosFormulario): CalculoIRPFType => {
  // Calcular todas las cuotas integras de todos los tramos,
  // teniendo como tope el bruto anual.
  // La cuota de la community es solo la mitad, el otro 50% viene
  // de la cuota estatal (Comunidad: Estado).
  // Cuota 2 (Autonómica)
  let cuotaComunidad = {
    cuota: 0,
    tramos: [] as TramoType[],
    baseImponible: 0,
    baseImponibleConReducciones: 0
  };
  let deduccionesComunidadAntesImpuestos = 0;
  let cuotaDeduccionesComunidad = {
    cuota: 0,
    tramos: [] as TramoType[],
    baseImponible: 0,
    baseImponibleConReducciones: 0
  };

  let cuotaEstatal = {
    cuota: 0,
    tramos: [] as TramoType[],
    baseImponible: 0,
    baseImponibleConReducciones: 0
  };
  let deduccionesEstadoAntesImpuestos = 0;
  let cuotaDeduccionesEstado = {
    cuota: 0,
    tramos: [] as TramoType[],
    baseImponible: 0,
    baseImponibleConReducciones: 0
  };

  let cuotaTotal = 0;

  if (grossAnnual > MINIMO_RETENCION_IRPF) {
    cuotaComunidad = calcularCuotaTramos(
      grossAnnual,
      year,
      community,
      false,
      dataMinimums,
      jointTaxReturn,
      categoriaProfesional
    );
    deduccionesComunidadAntesImpuestos = calcularDeducciones(
      dataMinimums,
      year,
      community,
      jointTaxReturn
    );
    // Cuota 4
    cuotaDeduccionesComunidad = calcularCuotaTramos(
      deduccionesComunidadAntesImpuestos,
      year,
      community,
      true,
      dataMinimums,
      jointTaxReturn,
      categoriaProfesional
    );
    // Cuota 1 (Estatal)
    cuotaEstatal = calcularCuotaTramos(
      grossAnnual,
      year,
      'Estado',
      false,
      dataMinimums,
      jointTaxReturn,
      categoriaProfesional
    );
    deduccionesEstadoAntesImpuestos = calcularDeducciones(
      dataMinimums,
      year,
      'Estado',
      jointTaxReturn
    );
    // Cuota 3
    cuotaDeduccionesEstado = calcularCuotaTramos(
      deduccionesEstadoAntesImpuestos,
      year,
      'Estado',
      true,
      dataMinimums,
      jointTaxReturn,
      categoriaProfesional
    );
    cuotaTotal =
      cuotaComunidad.cuota +
      cuotaEstatal.cuota -
      cuotaDeduccionesComunidad.cuota -
      cuotaDeduccionesEstado.cuota;
  }
  const seguridadSocialMensual = calcularSeguridadSocialMensualYBase(
    grossAnnual,
    year,
    categoriaProfesional
  );
  const sueldoNetoAnual =
    grossAnnual - cuotaTotal - seguridadSocialMensual.ssMensual * 12;
  return {
    cuotaComunidadAntesDeducciones: cuotaComunidad.cuota, // Cuota 2
    cuotaEstatalAntesDeducciones: cuotaEstatal.cuota, // Cuota 1
    cuotaComunidad: cuotaComunidad.cuota - cuotaDeduccionesComunidad.cuota, // Cuota íntegra general autonómica
    cuotaEstatal: cuotaEstatal.cuota - cuotaDeduccionesEstado.cuota, // Cuota íntegra general estatal
    cuotaTotalAntesDeducciones: cuotaComunidad.cuota + cuotaEstatal.cuota,
    cuotaTotal,
    sueldoNetoAnual,
    SueldoNetoMensual: sueldoNetoAnual / 12,
    ssMensual: seguridadSocialMensual.ssMensual,
    baseCotizacionSS: seguridadSocialMensual.baseCotizacionSS,
    tramosComunidad: cuotaComunidad.tramos,
    tramosEstatal: cuotaEstatal.tramos,
    baseImponible: cuotaComunidad.baseImponible,
    baseImponibleConReducciones: cuotaComunidad.baseImponibleConReducciones,
    cuotaDeduccionesComunidad: cuotaDeduccionesComunidad.cuota,
    cuotaDeduccionesEstado: cuotaDeduccionesEstado.cuota,
    deduccionesComunidadAntesImpuestos,
    deduccionesEstadoAntesImpuestos
  };
};

// TODO:
// El tercer hijo aun no funciona bien, se ve que el calculo por tramos no lo hace correctamente.
//  Ejemplo para 3 hijos.
// Calculo parte estatal:
// (5550+2400+2700+4000) = 14650
// El tope del primer tramo es 12450 (9.5%)
// 14650-12450 = 2200 sería para el segundo tramo (12%)
// en total:
// 2200×0.12+12450×0.095
//

const FormUpdateAnnualGrossSalary = ({ setGrossAnnual, styles }) => {
  const handleNumInputChange = (e) => {
    // Avoid decimals
    const value = e.target.value.replace(/\D/g, '');
    const num = parseInt(value, 10);
    if (!isNaN(num) && num >= 0) {
      setGrossAnnual(num);
    } else {
      setGrossAnnual(0);
    }
  };
  const preventInvalidInput = (e) => {
    // Prevent user from typing any non numeric character
    if (e.data && /\D/.test(e.data)) {
      e.preventDefault();
    }
  };
  return (
    <FormControl sx={styles.formControl} variant="outlined">
      <TextField
        label="Bruto anual (€)"
        sx={styles.formInput}
        type="number"
        onChange={handleNumInputChange}
        onBeforeInput={preventInvalidInput}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*'
        }}
      />
    </FormControl>
  );
};

/**
 * TODO: Los 1200euros de famlia numerosa, al 50% cuando no es renta conjunta,
 * se restan de la base imponible, por lo que no se suma a las deducciones.
 * En la calculadora de internet, baja justo 600 euros con el tercer hijo de la base imponible
 * De ahí que no salga en las deducciones.
 * Buscar en hacienda o fuentes alternativas, si esto es así. Porque al restarse
 * a la base imponible, se obtiene muchísimo mejor resultado, que si se suma a las deducciones.
 * Un 45% en vez de un 19% mas o menos para un ejempo de 70.000 euros de sueldo bruto.
 */

const Calculadora = () => {
  const [mostrarTodo, setMostrarTodo] = React.useState(false);
  // Bruto anual
  const [grossAnnual, setGrossAnnual] = React.useState(0);
  const [year, setYear] = React.useState(YEAR_DEFAULT);
  const [community, setComunidad] = React.useState(COMMUNITY_DEFAULT);
  const [edadContribuyente, setEdadContribuyente] = React.useState(
    AGE_CONTRIBUTOR_DEFAULT
  );
  const [descendientesMenores25, setDescendientesMenores25] = React.useState(0);
  const [descendientesMenores3, setDescendientesMenores3] = React.useState(0);
  const [descendientesFallecimiento, setDescendientesFallecimiento] =
    React.useState(0);
  const [ascendientesMayores65, setAscendientesMayores65] = React.useState(0);
  const [ascendientesMayores75, setAscendientesMayores75] = React.useState(0);
  const [ascendientesDiscapacitados, setAscendientesDiscapacitados] =
    React.useState(0);
  const [ascendientesFallecimiento, setAscendientesFallecimiento] =
    React.useState(0);
  const [discapacitadosEspeciales33, setDiscapacitadosEspeciales33] =
    React.useState(0);
  const [discapacitadosEspeciales65, setDiscapacitadosEspeciales65] =
    React.useState(0);
  const [
    discapacitadosEspecialesAyudaTerceros,
    setDiscapacitadosEspecialesAyudaTerceros
  ] = React.useState(0);
  const [jointTaxReturn, setJointTaxReturn] = React.useState(false);
  const [categoriaProfesional, setCategoriaProfesional] = React.useState(
    SS_PROFESSIONAL_CATEGORY_DEFAULT
  );

  const styles: { [key: string]: React.CSSProperties } = {
    main: {
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'transparent'
      }
    },
    container: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%'
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '90%',
        flexDirection: 'row'
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '80%'
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: '75%'
      },
      margin: '0 auto'
    },
    section: {
      // flex: 1
    },
    formContainer: {
      width: '100%',
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.background.paper,
        margin: '0 10px',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3]
      }
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row'
      }
    },
    formControl: {
      margin: `${theme.spacing(2)} 0`
    },
    formLabel: {
      marginBottom: '2px'
    },
    formInput: {
      width: '100%',
      color: '#4a5568'
    },
    resultNumber: {
      fontSize: '1.2rem'
    }
  };

  const datosFormulario: DatosFormulario = {
    grossAnnual,
    year,
    community: community,
    jointTaxReturn,
    dataMinimums: {
      taxPayer: {
        age: edadContribuyente
      },
      descendants: {
        menores25: descendientesMenores25,
        menores3: descendientesMenores3,
        death: descendientesFallecimiento
      },
      ascendants: {
        over65: ascendientesMayores65,
        over75: ascendientesMayores75,
        discapacitados: ascendientesDiscapacitados,
        death: ascendientesFallecimiento
      },
      specialDisability: {
        '>33': discapacitadosEspeciales33,
        '>65': discapacitadosEspeciales65,
        necesitaAyudaTerceros: discapacitadosEspecialesAyudaTerceros
      }
    },
    categoriaProfesional: categoriaProfesional
  };

  const costesIRPF = calcularIRPF(datosFormulario);

  return (
    <Grid sx={styles.main}>
      <ProfessionalTitle title="Calculadora de sueldo" />
      <Grid sx={styles.container}>
        <Box className="formContainer" sx={styles.formContainer}>
          {costesIRPF.sueldoNetoAnual < 0 && grossAnnual > 0 && (
            <Alert severity="warning" sx={styles.alert}>
              El salario bruto introducido es demasiado bajo y las cuotas de la
              seguridad social superan el salario bruto. Debes de introducir un
              salario bruto más alto.
            </Alert>
          )}
          <FormUpdateAnnualGrossSalary
            setGrossAnnual={setGrossAnnual}
            styles={styles}
          />
          <FormControl sx={styles.formControl} variant="outlined">
            <InputLabel>Año</InputLabel>
            <Select
              fullWidth
              sx={{
                width: '100%',
                color: '#4a5568'
              }}
              label="Año"
              value={year}
              onChange={(e) => setYear(e.target.value as string)}
            >
              <MenuItem value="2025">2025</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <InputLabel>Comunidad</InputLabel>
            <Select
              fullWidth
              value={community}
              label="Comunidad"
              onChange={(e) => setComunidad(e.target.value)}
            >
              {Object.keys(irpfComunidades[year]).map((community) => (
                <MenuItem value={community} key={community}>
                  {community}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <InputLabel>Categoría profesional</InputLabel>
            <Select
              fullWidth
              value={categoriaProfesional}
              label="Categoría profesional"
              onChange={(e) => setCategoriaProfesional(Number(e.target.value))}
            >
              {Object.keys(ssCategorias)?.map((categoriaId) => (
                <MenuItem value={categoriaId} key={ssCategorias[categoriaId]}>
                  {ssCategorias[categoriaId]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <InputLabel>Edad contribuyente</InputLabel>
            <Select
              label="Edad contribuyente"
              sx={styles.formInput}
              value={edadContribuyente}
              onChange={(e) => setEdadContribuyente(Number(e.target.value))}
            >
              <MenuItem value="60" key="60">
                {'<'} 65
              </MenuItem>
              <MenuItem value="66" key="66">
                {'>'} 65
              </MenuItem>
              <MenuItem value="76" key="76">
                {'>'} 75
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              label="Total hijos (< 25)"
              sx={styles.formInput}
              type="number"
              onChange={(e) =>
                setDescendientesMenores25(Number(e.target.value))
              }
              value={descendientesMenores25}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              label="Total hijos (< 3)"
              sx={styles.formInput}
              type="number"
              onChange={(e) => setDescendientesMenores3(Number(e.target.value))}
              value={descendientesMenores3}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              label="Descendientes fallecidos"
              sx={styles.formInput}
              type="number"
              onChange={(e) =>
                setDescendientesFallecimiento(Number(e.target.value))
              }
              value={descendientesFallecimiento}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              label="Ascendientes > 65"
              sx={styles.formInput}
              type="number"
              onChange={(e) => setAscendientesMayores65(Number(e.target.value))}
              value={ascendientesMayores65}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              label="Ascendientes > 75"
              sx={styles.formInput}
              type="number"
              onChange={(e) => setAscendientesMayores75(Number(e.target.value))}
              value={ascendientesMayores75}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              label="Ascendientes discapacitados"
              sx={styles.formInput}
              type="number"
              onChange={(e) =>
                setAscendientesDiscapacitados(Number(e.target.value))
              }
              value={ascendientesDiscapacitados}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              label="Ascendientes fallecidos"
              sx={styles.formInput}
              type="number"
              onChange={(e) =>
                setAscendientesFallecimiento(Number(e.target.value))
              }
              value={ascendientesFallecimiento}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              label="Discapacitados > 33%"
              sx={styles.formInput}
              type="number"
              onChange={(e) =>
                setDiscapacitadosEspeciales33(Number(e.target.value))
              }
              value={discapacitadosEspeciales33}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              label="Discapacitados > 65%"
              sx={styles.formInput}
              type="number"
              onChange={(e) =>
                setDiscapacitadosEspeciales65(Number(e.target.value))
              }
              value={discapacitadosEspeciales65}
            />
          </FormControl>
          <FormControl sx={styles.formControl} variant="outlined">
            <TextField
              label="Discapacitados con ayuda terceros"
              sx={styles.formInput}
              type="number"
              onChange={(e) =>
                setDiscapacitadosEspecialesAyudaTerceros(Number(e.target.value))
              }
              value={discapacitadosEspecialesAyudaTerceros}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                sx={styles.formInput}
                onChange={(e) => setJointTaxReturn(!jointTaxReturn)}
                checked={jointTaxReturn}
              />
            }
            label="Renta conjunta"
          />
        </Box>

        <Box className="datosCalculados" sx={styles.formContainer}>
          {(grossAnnual && grossAnnual >= MINIMO_SUELDO_BRUTO && (
            <Box>
              <Box>
                <Typography variant="h6" style={styles.resultLabel}>
                  Sueldo neto anual
                </Typography>
                <Typography variant="h5" style={styles.resultNumber}>
                  {costesIRPF.sueldoNetoAnual.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                  €
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6" style={styles.resultLabel}>
                  Sueldo neto mensual (12 pagas)
                </Typography>
                <Typography variant="h5" style={styles.resultNumber}>
                  {costesIRPF.SueldoNetoMensual.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                  €
                </Typography>
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setMostrarTodo(!mostrarTodo);
                }}
              >
                Mostrar todo
              </Button>
              {mostrarTodo && (
                <SalaryDetails costesIRPF={costesIRPF} community={community} />
              )}
            </Box>
          )) || (
              <Box>
                Introduce tu sueldo bruto anual para calcular el sueldo neto.
              </Box>
            ) || (
              <Box>
                {' '}
                Rellena el campo de bruto anual, para mostrar los datos de tu
                sueldo neto.
              </Box>
            )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Calculadora;
