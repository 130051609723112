import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  const styles = {
    footer: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '12px',
      alignItems: 'center',
      color: 'text.secondary',
      backgroundColor: 'background.paper',
      borderTop: '1px solid',
      borderColor: 'divider'
    }
  };
  return (
    <Box component="footer" sx={styles.footer}>
      <Typography variant="body2">© Sergio C</Typography>
    </Box>
  );
};

export default Footer;
