import Typography from '@mui/material/Typography';
import { Box, SvgIconTypeMap } from '@mui/material';
import theme from '../lib/theme';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export enum ThemeType {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

export default function ProfessionalTitle({
  title,
  showBorderTop = false,
  IconLeft,
  themeType = ThemeType.Primary
}: {
  title: string;
  showBorderTop?: boolean;
  IconLeft?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  themeType?: ThemeType;
}) {
  const styles = {
    iconPosition: {
      marginRight: theme.spacing(1)
    },
    primaryThemeBackground: {
      background: `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`
    },
    secondaryThemeBackground: {
      background: `linear-gradient(90deg, ${theme.palette.secondary.light}, ${theme.palette.secondary.dark})`
    },
    title: {
      color: theme.palette.text.secondary,
      textShadow: '3px 3px 2px rgba(0, 0, 0, 0.1)'
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
        textAlign: 'left',
        borderBottom: `2px solid ${
          themeType === ThemeType.Primary
            ? theme.palette.primary.main
            : theme.palette.secondary.main
        }`,
        borderTop: showBorderTop
          ? `2px solid ${
              themeType === ThemeType.Primary
                ? theme.palette.primary.main
                : theme.palette.secondary.main
            }`
          : 'none',
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
        marginBottom: theme.spacing(4),
        background:
          themeType === ThemeType.Primary
            ? styles.primaryThemeBackground.background
            : styles.secondaryThemeBackground.background
      }}
    >
      {IconLeft && <IconLeft sx={styles.iconPosition} />}
      <Typography variant="professionalTitle" sx={styles.title}>
        {title}
      </Typography>
    </Box>
  );
}
