import { green, grey, lime, red, teal } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import '@fontsource/exo-2';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primarySpacing: true;
  }
}

declare module '@mui/material/styles' {
  interface PaletteOptions {
    customBackground?: {
      light: string;
      dark: string;
    };
  }
  interface TypographyVariantsOptions {
    professionalTitle?: React.CSSProperties;
    logo?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    professionalTitle: true;
    logo: true;
  }
}

// Docs: https://mui.com/material-ui/customization/theming/
const theme = createTheme({
  palette: {
    primary: {
      light: green[200],
      main: green[500],
      contrastText: grey[50],
      dark: green[700]
    },
    secondary: {
      light: teal[200],
      main: teal[500],
      contrastText: grey[50]
    },
    background: {
      default: grey[300],
      paper: grey[100]
    },
    text: {
      primary: grey[900],
      secondary: grey[800]
    },
    error: {
      main: red[700],
      contrastText: '#ffffff'
    },
    customBackground: {
      light: grey[150],
      dark: grey[500]
    }
  },
  shape: {
    borderRadius: 3
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: { fontSize: '2.5rem', fontWeight: 700 },
    h2: { fontSize: '2rem', fontWeight: 700 },
    body1: { fontSize: '1rem', lineHeight: 1.5 },
    professionalTitle: {
      fontFamily: "'Exo 2', sans-serif",
      fontSize: '1.5rem',
      fontWeight: 800,
      color: '#333'
    },
    logo: {
      fontFamily: "'Exo 2', sans-serif",
      fontSize: '1.5rem',
      fontWeight: 800,
      color: '#333'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 430,
      md: 768,
      lg: 1440,
      xl: 1728
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          position: 'static'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              // New variant based on primary
              props: { variant: 'primarySpacing' },
              style: ({ theme }) => ({
                margin: theme.spacing(0.5),
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark
                }
              })
            }
          ]
        }
      }
    }
  }
});

export default theme;
