import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { months } from '../lib/time';
import { RecurringExpensesStore } from './RecurringExpensesStore';

export type YearlyExpense = {
  month: keyof typeof months;
  expenses: number;
  income: number;
  savings: number;
};

export class DailyExpensesStore {
  transportLayer;
  isLoading;
  isLoadingCategories;
  isSaving;
  isModified;
  categories: Category[];
  dailyExpenses: DailyExpense[];
  month;
  year;

  constructor(transportLayer) {
    makeAutoObservable(this, {
      // Choose from observable, computed, action
      // Setters should be actions
      // Getters should be computed
      // Own properties should be observable
      categories: observable,
      dailyExpenses: observable,
      isLoading: observable,
      isLoadingCategories: observable,
      isSaving: observable,
      isModified: observable,
      month: observable,
      year: observable,
      filterDailyExpensesMyMonthAndYear: action,
      setMonth: action,
      setYear: action,
      availableYears: computed
    });
    this.isLoading = false;
    this.isLoadingCategories = false;
    this.isSaving = false;
    this.isModified = false;
    this.categories = [];
    this.dailyExpenses = [];
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
    this.transportLayer = transportLayer;
  }

  filterDailyExpensesMyMonthAndYear(month = this.month, year = this.year) {
    return this.dailyExpenses.filter((gasto) => {
      const date = new Date(gasto.date);
      return date.getMonth() === month && date.getFullYear() === year;
    });
  }

  filterDailyExpensesByYear(year = this.year) {
    return this.dailyExpenses.filter((gasto) => {
      const date = new Date(gasto.date);
      return date.getFullYear() === year;
    });
  }

  getMonthlyExpensesAndSavings(year = this.year) {
    const monthlyExpenses: YearlyExpense[] = Array.from(
      { length: 12 },
      (_, i) => {
        const month = i;
        const expenses = this.filterDailyExpensesByYear(year)
          .filter((d) => !d.toDelete)
          .reduce((acc, gasto) => {
            const date = new Date(gasto.date);
            // Expenses are negative, so we only add the cost when is negative
            const isExpense = gasto.isExpense;
            return date.getMonth() === month && isExpense
              ? acc + gasto.cost
              : acc;
          }, 0);
        const income = this.filterDailyExpensesByYear(year)
          .filter((d) => !d.toDelete)
          .reduce((acc, gasto) => {
            const date = new Date(gasto.date);
            const isIncome = !gasto.isExpense;
            return date.getMonth() === month && isIncome
              ? acc + gasto.cost
              : acc;
          }, 0);
        return {
          month: new Date(year, month).toLocaleString('es-ES', {
            month: 'long'
          }) as keyof typeof months,
          expenses,
          income,
          savings: income + expenses
        };
      }
    );
    return monthlyExpenses;
  }

  setMonth(month) {
    this.month = month;
  }

  setYear(year) {
    this.year = year;
  }

  get availableYears() {
    return Array.from(
      new Set(
        this.dailyExpenses.map((gasto) => new Date(gasto.date).getFullYear())
      )
    );
  }

  async loadDailyExpenses() {
    this.isLoading = true;

    try {
      // Make sure categories are loaded before recurring expenses
      await this.loadCategories();

      // Get data from api
      const response = await this.transportLayer.getDailyExpenses();
      // Store ids to check the records that might be missing
      const serverIds = new Set(response.map((expense) => expense.id));

      runInAction(() => {
        // Add or update existing records
        response.forEach((expenseJson) =>
          this.updateDailyExpenseFromServer(expenseJson)
        );

        // Delete records that are not in the api anymore
        this.dailyExpenses = this.dailyExpenses.filter((expense) =>
          serverIds.has(expense.id)
        );

        this.isModified = false;
      });
    } catch (error) {
      console.error('Error al cargar los gastos diarios:', error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  updateDailyExpenseFromServer(json) {
    let dailyExpense = this.dailyExpenses.find((gasto) => gasto.id === json.id);
    if (!dailyExpense) {
      dailyExpense = new DailyExpense(this, json.id);
      this.dailyExpenses.push(dailyExpense);
    }
    dailyExpense.updateFromJson(json);
  }

  async loadCategories() {
    this.isLoadingCategories = true;
    const response = await this.transportLayer.getCategories();
    runInAction(() => {
      response?.forEach((categoryJson) =>
        this.updateCategoryFromServer(categoryJson)
      );
      this.isLoadingCategories = false;
    });
  }

  updateCategoryFromServer(json) {
    let category = this.categories.find((category) => category.id === json.id);
    if (!category) {
      category = new Category(this, json.id);
      this.categories.push(category);
    }
    category.updateFromJson(json);
  }

  removeDailyExpense(dailyExpense) {
    this.dailyExpenses = this.dailyExpenses.filter(
      (gasto) => gasto.id !== dailyExpense.id
    );
  }
  // Add a new daily expense to the store
  createDailyExpense(date = new Date(), concept = 'Nombre', cost = 0) {
    const dailyExpense = new DailyExpense(this, uuidv4());
    dailyExpense.date = date;
    dailyExpense.concept = concept;
    dailyExpense.cost = cost;
    dailyExpense.isExpense = true;
    const defaultCategory = this.categories.find(
      (category) => category.name === 'Otros'
    );
    if (defaultCategory) {
      dailyExpense.category = defaultCategory;
    }
    if (dailyExpense.cost > 0) {
      dailyExpense.isExpense = false;
    }
    this.dailyExpenses.push(dailyExpense);
    this.isModified = true;
    return dailyExpense;
  }

  // Add a new category to the store
  createCategory() {
    const category = new Category(this);
    this.categories.push(category);
    return category;
  }

  updateDailyExpense(json) {
    const dailyExpense = this.dailyExpenses.find(
      (expense) => expense.id === json.id
    );
    if (dailyExpense) {
      dailyExpense.updateFromJson(json);
    } else {
      this.dailyExpenses.push(new DailyExpense(this, json.id));
    }
  }

  updateCategory(json) {
    const category = this.categories.find(
      (category) => category.id === json.id
    );
    if (category) {
      category.updateFromJson(json);
    } else {
      this.categories.push(new Category(this, json.id));
    }
  }

  async updateDailyExpenses() {
    this.isSaving = true;
    await this.transportLayer.saveDailyExpenses(
      this.dailyExpenses.map((expense) => expense.toJson)
    );
    // Reload data
    this.isSaving = false;
    this.isModified = false;
    await this.loadDailyExpenses();
  }

  updateCategories() {
    this.transportLayer.saveCategories(
      this.categories.map((cat) => cat.toJson)
    );
  }

  get toJsonCSV() {
    return this.dailyExpenses
      .filter((d) => !d.toDelete)
      .map((expense) => expense.toJsonCSV);
  }
}

export class DailyExpense {
  store: DailyExpensesStore;
  id: String;
  userId: number = -1;
  category: Category;
  concept: string = '';
  cost: number = 0;
  date: Date = new Date();
  isNew: boolean = true;
  toDelete: boolean = false;
  isExpense: boolean = true;
  _forceUpdate: number = Date.now();

  constructor(store: DailyExpensesStore, id = '') {
    makeAutoObservable(this, {
      store: true,
      updateFromJson: action,
      delete: action,
      setCategory: action,
      toJson: computed,
      id: observable,
      userId: observable,
      category: observable,
      concept: observable,
      cost: observable,
      date: observable,
      isNew: observable,
      isExpense: observable,
      _forceUpdate: observable
    });
    this.store = store;
    this.id = id;
    this.category = new Category(this.store, store.categories[0]?.id);
  }

  setCategory(category) {
    this.category = category;
  }

  setConcept(concept) {
    this.store.isModified = true;
    this.concept = concept;
  }

  setCost(cost) {
    this.store.isModified = true;
    if (cost < 0) {
      this.isExpense = true;
    } else {
      this.isExpense = false;
    }
    this.cost = cost;
  }

  setDate(date) {
    this.store.isModified = true;
    this.date = date;
  }

  setIsExpense(isExpense) {
    this.store.isModified = true;
    this.isExpense = isExpense;
    if (this.isExpense) {
      this.cost = Math.abs(this.cost) * -1;
    } else {
      this.cost = Math.abs(this.cost);
    }
  }

  updateFromJson(json) {
    this.id = json.id;
    this.userId = json.userId;
    this.concept = json.concept;
    this.cost = json.cost;
    this.date = json.date;
    this.isNew = false;
    this.toDelete = false;
    this._forceUpdate = Date.now();
    const category = this.store.categories.find(
      (category) => category.id === json.categoryId
    );
    if (category) {
      this.category = category;
    }
    this.isExpense = json.isExpense;
  }

  delete() {
    this.store.isModified = true;
    this.toDelete = true;
  }

  get toJson() {
    return {
      id: this.id,
      userId: this.userId,
      categoryId: this.category.id,
      concept: this.concept,
      cost: this.cost,
      date: this.date,
      isNew: this.isNew,
      toDelete: this.toDelete,
      isExpense: this.isExpense
    };
  }

  get toJsonCSV() {
    return {
      concept: this.concept,
      cost: this.cost,
      date: this.date,
      category: this.category.name
    };
  }
}

export class Category {
  store: DailyExpensesStore | RecurringExpensesStore;
  id: string;
  name: string = '';
  isNew: boolean = true;

  constructor(store: DailyExpensesStore | RecurringExpensesStore, id = '') {
    makeAutoObservable(this, {
      store: true,
      id: true,
      name: true,
      isNew: true
    });
    this.store = store;
    this.id = id;
  }

  updateFromJson(json) {
    this.id = json.id;
    this.name = json.name;
    this.isNew = false;
  }

  delete() {
    this.store.categories = this.store.categories.filter(
      (category) => category.id !== this.id
    );
  }

  get toJson() {
    return {
      id: this.id,
      name: this.name,
      isNew: this.isNew
    };
  }
}
