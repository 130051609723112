import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Menu from '../../menu/Menu';
import { Box } from '@mui/material';
import Footer from './Footer';
import theme from '../lib/theme';
import Progress from './Progress';
import { RootStoreContext } from '../states/RootStore';
import { observer } from 'mobx-react';

const Layout = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: 'customBackground.light'
    },
    subContainer: {
      flex: 1,
      paddingBottom: theme.spacing(4)
    }
  };
  const storeContext = useContext(RootStoreContext);
  const isLoading = storeContext.isLoading();
  const isSaving = storeContext.isSaving();
  const isVisible = isLoading || isSaving;
  const message = isLoading ? 'Cargando' : 'Guardando';
  return (
    <Box sx={styles.container}>
      <Menu />
      <Box sx={styles.subContainer}>
        <Progress isVisible={isVisible} message={message} />
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default observer(Layout);
