import { Box, Button, Typography, Modal } from '@mui/material';
import React from 'react';
import ResponsiveTable from '../../core/components/ResponsiveTable';
import { DailyExpensesStore } from '../states/DailyExpensesStore';

interface ModalConfirmationProps {
  setFileData: React.Dispatch<React.SetStateAction<any[]>>;
  fileData: any[];
  store: DailyExpensesStore;
  handleDataStoreImport: (data: any[]) => void;
}

export const ModalConfirmation = ({
  setFileData,
  fileData,
  store,
  handleDataStoreImport
}: ModalConfirmationProps) => {
  return (
    <Modal
      open={fileData.length > 0}
      onClose={() => setFileData([])}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50vw',
          height: '50vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 2,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Confirmación de importación
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Por favor, revise los datos y confirme que desea importarlos.
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            border: '1px solid #e0e0e0',
            borderRadius: 1
          }}
        >
          <ResponsiveTable
            isLoading={store.isLoading}
            data={fileData}
            columns={[
              {
                label: 'Concepto',
                renderCell: (row: any) => <Typography>{row.concept}</Typography>
              },
              {
                label: 'Importe',
                renderCell: (row: any) => (
                  <Typography>
                    {row.cost.toLocaleString('es-ES', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                    €
                  </Typography>
                )
              },
              {
                label: 'Fecha',
                renderCell: (row: any) => (
                  <Typography>
                    {row.date.toLocaleString('es-ES', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </Typography>
                )
              }
            ]}
          />
        </Box>
        <Button
          onClick={async () => {
            await handleDataStoreImport(fileData);
            setFileData([]);
          }}
          sx={{ mt: 2 }}
          variant="contained"
        >
          Importar
        </Button>
        <Button onClick={() => setFileData([])}>Cancelar</Button>
      </Box>
    </Modal>
  );
};
