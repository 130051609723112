const ExcelJS = require('exceljs');

type ExportExcellProps = {
  data: any[];
  fileName?: string;
};
// Based on:
// https://github.com/exceljs/exceljs/issues/354#issuecomment-442801401
// https://github.com/exceljs/exceljs
export const exportToExcell = async ({
  data,
  fileName = 'Download.xls'
}: ExportExcellProps) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Datos');
  // Get row names
  const rowNames = Object.keys(data[0]);
  sheet.columns = rowNames.map((name) => ({ header: name, key: name }));
  for (const row of data) {
    sheet.addRow(row);
  }
  const dataBuffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([dataBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
};
