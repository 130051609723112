import axios from 'axios';
import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getFullUrl } from '../lib/helper';
import { observer } from 'mobx-react';
import { RootStoreContext } from '../states/RootStore';

export const PrivateRoute = observer(({ children, ...rest }) => {
  const rootStore = useContext(RootStoreContext);
  const fullUrl = getFullUrl();
  // Check if token from localStorage is still valid
  // If not, delete it
  React.useEffect(() => {
    // If we aren't in the login page
    if (window.location.pathname === '/login') {
      return;
    }
    const token = rootStore.token; // localStorage.getItem('token');
    if (token) {
      axios
        .get(`${fullUrl}/api/common/verifyToken`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((token) => {
          if (!token.data.verified) {
            rootStore.clearToken();
            // localStorage.removeItem('token');
            // Redirect to login page
            window.location.href = '/login';
          }
        })
        .catch(() => {
          rootStore.clearToken();
          // localStorage.removeItem('token');
          // Redirect to login page
          window.location.href = '/login';
        });
    }
  });

  const location = useLocation();
  return rootStore.token ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
});
