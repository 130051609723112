import { DailyExpense } from '../../personalExpenses/states/DailyExpensesStore';
import { RecurringExpense } from '../../personalExpenses/states/RecurringExpensesStore';
import {
  calcularIRPF,
  DatosFormulario,
  SS_PROFESSIONAL_CATEGORY_DEFAULT
} from '../../salaryCalculator/components/SalaryCalculator';
import { Configuration } from '../states/ConfigurationStore';
import { PersonalGoal } from '../states/PersonalGoalsStore';
import { Periodicity } from '@prisma/client';

export const getYearAgo = () =>
  new Date(new Date().setFullYear(new Date().getFullYear() - 1));
export const getEndThisYear = () => new Date(new Date().getFullYear(), 11, 31);

export const getFuture10Years = () =>
  new Date(new Date().setFullYear(new Date().getFullYear() + 10));

export const calculateCostByPeriodicity = (
  expense,
  dateStartRange,
  dateEndRange
) => {
  const startDate = expense.from
    ? new Date(expense.from) > new Date(dateStartRange)
      ? new Date(expense.from)
      : new Date(dateStartRange)
    : new Date(dateStartRange);

  const endDate = expense.to
    ? new Date(expense.to) < new Date(dateEndRange)
      ? new Date(expense.to)
      : new Date(dateEndRange)
    : new Date(dateEndRange);

  if (startDate > endDate) {
    return 0;
  }

  const totalDays = getDaysBetweenDates(startDate, endDate);
  const totalMonths = getMonthsFromDateRange(startDate, endDate);
  const totalYears = totalMonths / 12;

  const getOccurrencesInRange = (interval: number) => {
    let count = 0;
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      count++;
      currentDate.setMonth(currentDate.getMonth() + interval);
    }

    return count;
  };

  switch (expense.periodicity) {
    case Periodicity.ANUAL:
      return expense.cost * getOccurrencesInRange(12);
    case Periodicity.SEMESTRAL:
      return expense.cost * getOccurrencesInRange(6);
    case Periodicity.TRIMESTRAL:
      return expense.cost * getOccurrencesInRange(3);
    case Periodicity.BIMENSUAL:
      return expense.cost * getOccurrencesInRange(2);
    case Periodicity.MENSUAL:
      return expense.cost * getOccurrencesInRange(1);
    case Periodicity.QUINCENAL:
      return expense.cost * (totalDays / 15);
    case Periodicity.SEMANAL:
      return expense.cost * (totalDays / 7);
    case Periodicity.DIARIO:
      return expense.cost * totalDays;
    default:
      return expense.cost;
  }
};

type MonthlySavingsProps = {
  dailyExpenses: DailyExpense[];
  recurringExpenses: RecurringExpense[];
  useEstimatedRecurringExpenses?: boolean;
  useNonEstimatedRecurringExpenses?: boolean;
  dateStartRange?: Date;
  dateEndRange?: Date;
  configuration: Configuration;
};

export const netSalaryCalculator = ({ grossSalary }) => {
  const datosFormulario: DatosFormulario = {
    grossAnnual: grossSalary,
    year: new Date().getFullYear().toString(),
    community: 'Estado',
    jointTaxReturn: false,
    dataMinimums: {
      taxPayer: {
        age: 30
      },
      descendants: {
        menores25: 0,
        menores3: 0,
        death: 0
      },
      ascendants: {
        over65: 0,
        over75: 0,
        discapacitados: 0,
        death: 0
      },
      specialDisability: {
        '>33': 0,
        '>65': 0,
        necesitaAyudaTerceros: 0
      }
    },
    categoriaProfesional: SS_PROFESSIONAL_CATEGORY_DEFAULT
  };

  const costesIRPF = calcularIRPF(datosFormulario);
  // Gross salary should be greater than 0
  let monthlyNetSalary = grossSalary > 0 ? costesIRPF.SueldoNetoMensual : 0;
  // Monthly net salary should be greater than 0
  monthlyNetSalary = monthlyNetSalary > 0 ? monthlyNetSalary : 0;
  return monthlyNetSalary;
};

export const calculateGroupedGoalDate = ({
  personalGoals,
  dailyExpenses,
  recurringExpenses,
  configuration
}: {
  personalGoals: PersonalGoal[];
  dailyExpenses: DailyExpense[];
  recurringExpenses: RecurringExpense[];
  configuration: Configuration | undefined;
}): {
  goalDate: string | null;
  monthlySavings: number;
} => {
  let quantityGoal = 0;
  for (const element of personalGoals) {
    if (element.toDelete) {
      continue;
    }
    quantityGoal += element.quantity;
  }
  const monthlySavings = calculateMonthlySavings({
    dailyExpenses,
    recurringExpenses,
    useEstimatedRecurringExpenses: configuration?.useEstimatedRecurringExpenses,
    useNonEstimatedRecurringExpenses:
      configuration?.useNonEstimatedRecurringExpenses,
    dateStartRange: configuration?.dateStartRange ?? undefined,
    dateEndRange: configuration?.dateEndRange ?? undefined,
    configuration: configuration as Configuration
  });

  const goalDate = calculateSavingsGoalDate({
    monthlySavings,
    quantityGoal
  });
  return {
    goalDate: goalDate ? showDateFormat(goalDate) : null,
    monthlySavings
  };
};

// Get total months between two dates
const getMonthsFromDateRange = (dateStartRange, dateEndRange) => {
  const dateStart = new Date(dateStartRange);
  const dateEnd = new Date(dateEndRange);

  const normalizedStartDate = new Date(
    dateStart.getFullYear(),
    dateStart.getMonth(),
    dateStart.getDate()
  );
  const normalizedEndDate = new Date(
    dateEnd.getFullYear(),
    dateEnd.getMonth(),
    dateEnd.getDate()
  );

  let yearsDifference =
    normalizedEndDate.getFullYear() - normalizedStartDate.getFullYear();
  let monthsDifference =
    normalizedEndDate.getMonth() - normalizedStartDate.getMonth();
  let daysDifference =
    normalizedEndDate.getDate() - normalizedStartDate.getDate();

  if (daysDifference < 0) {
    monthsDifference -= 1;
    const lastMonthDays = new Date(
      normalizedEndDate.getFullYear(),
      normalizedEndDate.getMonth(),
      0
    ).getDate();
    daysDifference += lastMonthDays;
  }

  const totalMonths = yearsDifference * 12 + monthsDifference;
  const fractionOfMonth = daysDifference / 30;

  return Math.max(totalMonths + fractionOfMonth, 0);
};

// Get total of days between two dates
export const getDaysBetweenDates = (
  startDate: Date,
  endDate: Date,
  includeEndDaySeconds: boolean = false
): number => {
  const normalizedStartDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const normalizedEndDate = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );

  if (includeEndDaySeconds) {
    normalizedEndDate.setHours(23, 59, 59, 999);
  }

  const differenceInTime =
    normalizedEndDate.getTime() - normalizedStartDate.getTime();

  return Math.max(Math.ceil(differenceInTime / (1000 * 60 * 60 * 24)), 0);
};

const dateRecurringCondition = (
  recurringDateObject,
  dateStartRange,
  dateEndRange
) => {
  const fromCondition =
    !recurringDateObject?.from ||
    new Date(recurringDateObject.from).getTime() <=
      new Date(dateEndRange).getTime();

  const toCondition =
    !recurringDateObject?.to ||
    new Date(recurringDateObject.to).getTime() >=
      new Date(dateStartRange).getTime();

  if (!recurringDateObject?.from) {
    return toCondition;
  }

  if (!recurringDateObject?.to) {
    return fromCondition;
  }

  return fromCondition && toCondition;
};

enum NominaInUse {
  DAILY_EXPENSES = 'Gastos / Ingresos Diarios',
  RECURRING_EXPENSES = 'Gastos / Ingresos recurrentes',
  CONFIGURATION = 'Configuración'
}

// Based on the data (daily expenses, recurring expenses, configuration)
// the algorithm will determine what nomina should be used.
// Recurring expenses got priority.
// If there is no recurrig expenses, then daily expenses got priority.
// If there is no daily expenses, then configuration got priority.
export const whatNominaIsInUse = ({
  dailyExpenses,
  recurringExpenses,
  configuration
}): NominaInUse | null => {
  const dateStartRange = configuration?.dateStartRange ?? getYearAgo();
  const dateEndRange = configuration?.dateEndRange ?? getEndThisYear();
  let what: NominaInUse | null = null;

  const dateDailyCondition = (dailyDateObject) => {
    return (
      new Date(dailyDateObject.date).getTime() >=
        new Date(dateStartRange).getTime() &&
      new Date(dailyDateObject.date).getTime() <=
        new Date(dateEndRange).getTime()
    );
  };
  const anyNominaInDailyExpenses = dailyExpenses
    .filter((d) => !d.isExpense)
    .filter(dateDailyCondition)
    .some((d) => d.category.name === 'Nomina');
  const anyNominaInRecurringExpenses = recurringExpenses
    .filter((r) => !r.isExpense)
    // From and to are optional fields
    // If they are null or undefined, they won't be considered
    // Otherwise, they will be considered
    .filter((r) => dateRecurringCondition(r, dateStartRange, dateEndRange))
    .some((r) => r.category.name === 'Nomina');
  const anyNominaInConfiguration = configuration?.grossSalary > 0;
  const useEstimatedRecurringExpenses =
    configuration?.useEstimatedRecurringExpenses;
  const useNonEstimatedRecurringExpenses =
    configuration?.useNonEstimatedRecurringExpenses;
  if (
    anyNominaInRecurringExpenses &&
    (useEstimatedRecurringExpenses || useNonEstimatedRecurringExpenses)
  ) {
    what = NominaInUse.RECURRING_EXPENSES;
  } else if (anyNominaInDailyExpenses) {
    what = NominaInUse.DAILY_EXPENSES;
  } else if (anyNominaInConfiguration) {
    what = NominaInUse.CONFIGURATION;
  }
  return what;
};

export const calculateMonthlySavings = ({
  dailyExpenses,
  recurringExpenses,
  useEstimatedRecurringExpenses = false,
  useNonEstimatedRecurringExpenses = false,
  dateStartRange = getYearAgo(),
  dateEndRange = getEndThisYear(),
  configuration
}: MonthlySavingsProps) => {
  let netMonthlySalary = 0;

  const dateDailyCondition = (dailyDateObject) => {
    return (
      new Date(dailyDateObject.date).getTime() >=
        new Date(dateStartRange).getTime() &&
      new Date(dailyDateObject.date).getTime() <=
        new Date(dateEndRange).getTime()
    );
  };

  const monthsInDateRange = getMonthsFromDateRange(
    dateStartRange,
    dateEndRange
  );

  // Total daily costs
  let dailyExpensesCost = dailyExpenses
    .filter((d) => d.isExpense)
    .filter(dateDailyCondition)
    .reduce((acc, expense) => acc + expense.cost, 0);
  // Total daily income without nomina
  let dailyExpensesIncomeWithoutNomina = dailyExpenses
    .filter((d) => !d.isExpense)
    .filter((d) => d.category.name !== 'Nomina')
    .filter(dateDailyCondition)
    .reduce((acc, expense) => acc + expense.cost, 0);
  // Total daily income categorised as "Nomina"
  let dailyExpensesIncomeWithNomina = dailyExpenses
    .filter((d) => !d.isExpense)
    .filter((d) => d.category.name === 'Nomina')
    .filter(dateDailyCondition)
    .reduce((acc, expense) => acc + expense.cost, 0);
  // Any daily expense set as income and categorised as "Nomina"
  const anyNominaInDailyExpenses = dailyExpenses
    .filter((d) => !d.isExpense)
    .filter(dateDailyCondition)
    .some((d) => d.category.name === 'Nomina');
  // Any recurring expense set as income and categorised as "Nomina"
  /*const anyIncomeInRecurringExpenses = recurringExpenses
    .filter((r) => !r.isExpense)
    // From and to are optional fields
    // If they are null or undefined, they won't be considered
    // Otherwise, they will be considered
    .filter(dateRecurringCondition)*/
  const anyNominaInRecurringExpensesNonEstimated = recurringExpenses
    .filter((r) => !r.isExpense)
    .filter((r) => !r.estimation)
    .filter((r) => dateRecurringCondition(r, dateStartRange, dateEndRange))
    .some((r) => r.category.name === 'Nomina');
  const anyNominaInRecurringExpensesEstimated = recurringExpenses
    .filter((r) => !r.isExpense)
    .filter((r) => r.estimation)
    .filter((r) => dateRecurringCondition(r, dateStartRange, dateEndRange))
    .some((r) => r.category.name === 'Nomina');
  const anyNominaInConfiguration = configuration?.grossSalary > 0;
  // Lets calculate the yearly savings
  // Firstly we add daily yearly savings without nomina.
  let yearlySavings = dailyExpensesIncomeWithoutNomina + dailyExpensesCost;
  if (useEstimatedRecurringExpenses) {
    // Estimated recurring expenses
    const recurringExpensesCostEstimated = recurringExpenses
      .filter((r) => r.isExpense)
      .filter((r) => r.estimation)
      .filter((r) => dateRecurringCondition(r, dateStartRange, dateEndRange))
      .reduce(
        (acc, expense) =>
          acc +
          calculateCostByPeriodicity(expense, dateStartRange, dateEndRange),
        0
      );
    // Estimated recurring income
    const recurringExpensesIncomeEstimated = recurringExpenses
      .filter((r) => !r.isExpense)
      .filter((r) => r.estimation)
      .filter((r) => dateRecurringCondition(r, dateStartRange, dateEndRange))
      .reduce(
        (acc, expense) =>
          acc +
          calculateCostByPeriodicity(expense, dateStartRange, dateEndRange),
        0
      );
    // Add yearly savings from recurring expenses
    yearlySavings +=
      recurringExpensesIncomeEstimated + recurringExpensesCostEstimated;
  }
  if (useNonEstimatedRecurringExpenses) {
    // Non estimated recurring expenses
    const recurringExpensesCostNonEstimated = recurringExpenses
      .filter((r) => r.isExpense)
      .filter((r) => !r.estimation)
      .filter((r) => dateRecurringCondition(r, dateStartRange, dateEndRange))
      .reduce(
        (acc, expense) =>
          acc +
          calculateCostByPeriodicity(expense, dateStartRange, dateEndRange),
        0
      );

    // Non estimated recurring income
    const recurringExpensesIncomeNonEstimated = recurringExpenses
      .filter((r) => !r.isExpense)
      .filter((r) => !r.estimation)
      .filter((r) => dateRecurringCondition(r, dateStartRange, dateEndRange))
      .reduce(
        (acc, expense) =>
          acc +
          calculateCostByPeriodicity(expense, dateStartRange, dateEndRange),
        0
      );
    // Add yearly savings from recurring expenses
    yearlySavings +=
      recurringExpensesIncomeNonEstimated + recurringExpensesCostNonEstimated;
  }

  // Without taking in account any other nomina, can we use recurring expenses nomina?
  // We can use recurring expenses nomina when:
  // - There is any nomina in recurring expenses non estimated
  // - And the user chose to use non estimated recurring expenses
  // - Or there is any nomina in recurring expenses estimated
  // - And the user chose to use estimated recurring expenses
  const canUseRecurringExpensesNomina =
    (anyNominaInRecurringExpensesEstimated && useEstimatedRecurringExpenses) ||
    (anyNominaInRecurringExpensesNonEstimated &&
      useNonEstimatedRecurringExpenses);

  // Check if daily expenses nomina has priority over recurring expenses nomina
  const dailyExpensesNominaHasPriority =
    anyNominaInDailyExpenses && !canUseRecurringExpensesNomina;

  // If daily expenses nomina has more priority over recurring expenses nomina
  // We use daily expenses nomina
  if (dailyExpensesNominaHasPriority) {
    yearlySavings += dailyExpensesIncomeWithNomina;
  }

  let monthlySavings = 0;
  if (yearlySavings !== 0) {
    monthlySavings = yearlySavings / monthsInDateRange;
  }

  // If there is no income either on daily expenses or recurring expenses,
  // or there is income on recurring expenses, but user chose to not use it,
  // then the system will calculate the net salary and use it as income.
  if (
    !dailyExpensesNominaHasPriority &&
    !canUseRecurringExpensesNomina &&
    anyNominaInConfiguration
  ) {
    netMonthlySalary = netSalaryCalculator({
      grossSalary: configuration?.grossSalary
    });
    monthlySavings += netMonthlySalary;
  }
  return monthlySavings;
};

export const calculateSavingsGoalDate = ({
  monthlySavings,
  quantityGoal
}: {
  monthlySavings: number;
  quantityGoal: number;
}): Date | null => {
  // Let's make sure we are working with numbers
  const quantityGoalNumber = Number.isNaN(quantityGoal) ? 0 : quantityGoal;
  if (monthlySavings <= 0) {
    // If there is any savings, there won't be any goal date
    return null;
  }
  const months = quantityGoalNumber / monthlySavings;
  const fullMonths = Math.floor(months);
  const fractionalMonths = months - fullMonths;
  const today = new Date();

  // Add full months to today
  today.setMonth(today.getMonth() + fullMonths);
  // Calculate the number of days
  const daysToAdd = Math.round(fractionalMonths * 30);
  // Add days to today
  today.setDate(today.getDate() + daysToAdd);
  const goalDate = new Date(today);

  return goalDate;
};

export const showDateFormat = (date: Date | null) => {
  if (!date) {
    return '';
  }
  return date.toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};
