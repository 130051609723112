import React, { useContext } from 'react';
import { RootStoreContext } from '../../core/states/RootStore';
import {
  Alert,
  AlertColor,
  AlertPropsColorOverrides,
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TableContainer,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { observer } from 'mobx-react';
import {
  DailyExpense,
  DailyExpensesStore,
  YearlyExpense
} from '../states/DailyExpensesStore';
import { months } from '../lib/time';
import {
  Save as SaveIcon,
  AddCircle as AddCircleIcon
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { OverridableStringUnion } from '@mui/types';
import ProfessionalTitle from '../../core/components/ProfessionalTitle';
import theme from '../../core/lib/theme';
import ResponsiveTable from '../../core/components/ResponsiveTable';
import { exportToExcell } from '../../core/lib/export';
import { Configuration } from '../../personalGoals/states/ConfigurationStore';
import { RecurringExpense } from '../states/RecurringExpensesStore';
import { runInAction } from 'mobx';
import { handleImportFile } from '../lib/import';
import { ModalConfirmation } from './ModalConfirmation';
import {
  getFormattedFloatValue,
  handleImportFloatBlur,
  handleImportFloatChange
} from '../../core/lib/helper';

const DailyExpenses = observer(() => {
  const storeContext = useContext(RootStoreContext);
  const store = storeContext.dailyExpensesStore;
  const recurringExpensesStore = storeContext.recurringExpensesStore;
  const configurationStore = storeContext.configurationStore;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const [selectedYear, setSelectedYear] = React.useState(currentYear);
  const [selectedMonth, setSelectedMonth] = React.useState(currentMonth);
  const [dailyExpenses, setDailyExpenses] = React.useState<DailyExpense[]>([]);
  const [filteredExpenses, setFilteredExpenses] = React.useState<
    DailyExpense[]
  >([]);
  const [configuration, setConfiguration] = React.useState<Configuration>();
  const [recurringExpenses, setRecurringExpenses] = React.useState<
    RecurringExpense[]
  >([]);
  const [fileData, setFileData] = React.useState<any[]>([]);

  const styles = {
    buttonActionNormal: {
      width: '100px',
      height: '30px'
    },
    buttonsMargin: {
      margin: `${theme.spacing(1)} 0`
    },
    primaryButtonSelected: {
      backgroundColor: theme.palette.primary.dark,
      textDecoration: 'underline'
    },
    secondaryButtonSelected: {
      backgroundColor: theme.palette.secondary.dark,
      textDecoration: 'underline'
    },
    container: {
      margin: theme.spacing(2)
    },
    yearsContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: `${theme.spacing(1)} 0`
    },
    monthsContainer: {
      margin: `${theme.spacing(1)} 0`,
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: 1,
      // Mobile versions
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(4, 1fr)'
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(6, 1fr)'
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(12, 1fr)'
      }
    },
    tableContainer: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[3],
      padding: theme.spacing(1),
      width: '100%',
      overflow: 'auto',
      maxWidth: { sm: '90%', md: '80%', lg: '70%', xl: '60%' }
    },
    tableCell: {
      fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
      padding: { xs: '2px', sm: '4px', md: '6px', xl: '8px' }
    },
    tableInput: {
      width: '100%',
      padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
      maxWidth: '100px'
    },
    resultLabel: {
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    resultNumber: {
      fontSize: '1.2rem'
    },
    exportContainer: {
      // Align to the right
      display: 'flex',
      justifyContent: 'flex-end'
    },
    alert: {
      margin: `${theme.spacing(1)} 0`
    },
    cell: {
      width: { xs: '100%', sm: 'auto' }
    }
  };

  const filterExpensesByMonthAndYear = (month, year) => {
    setFilteredExpenses(store.filterDailyExpensesMyMonthAndYear(month, year));
  };

  React.useEffect(() => {
    const loadExpenses = async () => {
      await store.loadDailyExpenses();
      setDailyExpenses(store.dailyExpenses);
      setFilteredExpenses(
        store.filterDailyExpensesMyMonthAndYear(selectedMonth, selectedYear)
      );
    };
    loadExpenses();
    const loadConfiguration = async () => {
      await configurationStore.loadConfiguration();
      setConfiguration(configurationStore.configuration ?? undefined);
    };
    loadConfiguration();
    const loadRecurringExpenses = async () => {
      await recurringExpensesStore.loadRecurringExpenses();
      setRecurringExpenses(recurringExpensesStore.recurringExpenses);
    };
    loadRecurringExpenses();
  }, [
    store,
    configurationStore,
    recurringExpensesStore,
    selectedYear,
    selectedMonth
  ]);

  const getAvailableYears = () => {
    const years = store.availableYears;
    if (years.length === 0) {
      return [currentYear];
    }
    return years;
  };

  const handleCreateDailyExpense = () => {
    // Add new expense for the selected month and year
    const date = new Date(selectedYear, selectedMonth + 1, 0);
    store.createDailyExpense(date);
  };

  const handleSelectionFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const data = await handleImportFile(event);
      setFileData(data);
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
      if (error instanceof Error) {
        alert('Error al cargar el archivo: ' + error.message);
      } else {
        alert('Error al cargar el archivo');
      }
    }
    // Reset the name of the file. Otherwise, the browser will
    // cache the file, and won't trigger the event if
    // the user tries to upload the same file
    event.target.value = '';
  };

  const handleDataStoreImport = async (data: any[]) => {
    let rowsAppended = 0;
    data.map((row) => {
      if (row.date && row.cost && row.concept) {
        store.createDailyExpense(
          new Date(row.date),
          row.concept || '',
          row.cost || 0
        );
        rowsAppended++;
      }
    });
    if (rowsAppended > 0) {
      await store.updateDailyExpenses();
    }
  };

  const ShowSalaryMessage = observer(
    ({ store }: { store: DailyExpensesStore }): React.JSX.Element => {
      const styles = {
        alert: {
          margin: `${theme.spacing(1)} 0`
        }
      };
      const nominasInDailyExpenses = dailyExpenses
        .filter((d) => !d.isExpense)
        .filter((d) => !d.toDelete)
        .filter((d) => d.category.name === 'Nomina');
      const anyNominaInDailyExpenses = nominasInDailyExpenses.length > 0;
      const anyNominaInRecurringExpenses = recurringExpenses
        .filter((d) => !d.isExpense)
        .filter((d) => !d.toDelete)
        .some((d) => d.category.name === 'Nomina');
      const anyNominaInConfiguration = (configuration?.grossSalary ?? 0) > 0;
      let message = '';
      let severity:
        | OverridableStringUnion<AlertColor, AlertPropsColorOverrides>
        | undefined = 'info';
      if (
        !anyNominaInDailyExpenses &&
        !anyNominaInRecurringExpenses &&
        !anyNominaInConfiguration
      ) {
        message =
          'No hay ninguna nómina registrada ni en movimientos diarios, ni en gastos recurrentes, ni en el apartado de Nómima en la configuración de metas. Se recomienda añadir una nómina en gastos recurrentes para poder calcular el ahorro mensual.';
        severity = 'warning';
      } else if (
        !anyNominaInDailyExpenses &&
        anyNominaInRecurringExpenses &&
        !anyNominaInConfiguration
      ) {
        message =
          'No hay ninguna nómina registrada en movimientos diarios pero sí que has registrado al menos una nómina en los ingresos recurrentes. Se tomará dicho valor para calcular el ahorro mensual de las metas de ahorro. ¡Bien hecho!';
        severity = 'success';
      } else if (
        !anyNominaInDailyExpenses &&
        !anyNominaInRecurringExpenses &&
        anyNominaInConfiguration
      ) {
        message =
          'No hay ninguna nómina registrada en movimientos diarios, ni en ingresos recurrentes, pero sí que has registrado un salario en la configuración de metas. Se tomará dicho valor para calcular el ahorro mensual de las metas de ahorro. No obstante, se recomienda añadir una nómina en gastos recurrentes para poder calcular el ahorro mensual de forma más precisa. Esto es así, porque el sueldo neto que se calcula a partir del salario bruto de la configuración de metas, no tiene en cuenta la comunidad en la que vives.';
        severity = 'warning';
      } else if (
        nominasInDailyExpenses.length < 12 &&
        !anyNominaInRecurringExpenses &&
        !anyNominaInConfiguration
      ) {
        message =
          'Has registrado al menos una nómina en los movimientos diarios. Se tomará dicho valor para calcular el ahorro mensual de las metas de ahorro. Pero debes de tener en cuenta, que si no tienes una nómina registrada en todos los meses y sí que has obtenido dichas nóminas en tu cuenta bancaria, el cálculo del ahorro mensual no será preciso. Se recomienda añadir una nómina en gastos recurrentes para poder calcular el ahorro mensual de forma más precisa. O bien introducir todas las nóminas recibidas en los movimientos diarios.';
        severity = 'warning';
      } else if (
        !anyNominaInDailyExpenses &&
        anyNominaInRecurringExpenses &&
        anyNominaInConfiguration
      ) {
        message =
          'Has registrado una nómina en los ingresos recurrentes y en la configuración de metas. Se tomará el valor de la nómina de los ingresos recurrentes para calcular el ahorro mensual de las metas de ahorro. ¡Bien hecho!';
        severity = 'info';
      } else if (
        anyNominaInDailyExpenses &&
        anyNominaInRecurringExpenses &&
        !anyNominaInConfiguration
      ) {
        message =
          'Has registrado al menos una nómina en los ingresos recurrentes, que tiene prioridad sobre las nóminas registradas en los movimientos diarios. Se tomará el valor de la nómina de los ingresos recurrentes para calcular el ahorro mensual de las metas de ahorro. Si quieres que se tome en cuenta únicamente las nóminas de los movimientos diarios, elimina la nómina de los ingresos recurrentes. ¡Pero no se recomienda! Si tienes nóminas que van actualizando el valor, ya sea a la baja o al alza, puedes registrarlas en los movimientos recurrentes con diferentes fechas.';
        severity = 'info';
      } else if (
        anyNominaInDailyExpenses &&
        anyNominaInRecurringExpenses &&
        anyNominaInConfiguration
      ) {
        message =
          'Has registrado al menos una nómina en los ingresos diarios, en los ingresos recurrentes y en la configuración de metas. Se tomará el valor de la nómina de los ingresos recurrentes para calcular el ahorro mensual de las metas de ahorro. Si quieres que se tome en cuenta únicamente las nóminas de los movimientos diarios, elimina la nómina de los ingresos recurrentes. ¡Pero no se recomienda! Si tienes nóminas que van actualizando el valor, ya sea a la baja o al alza, puedes registrarlas en los movimientos recurrentes con diferentes fechas.';
        severity = 'info';
      }

      return (
        (message && (
          <Alert sx={styles.alert} severity={severity}>
            {message}
          </Alert>
        )) || <></>
      );
    }
  );

  return (
    <Box>
      <ProfessionalTitle title="Gastos/Ingresos diarios" />
      <Box sx={styles.container}>
        <Box sx={styles.exportContainer}>
          {filteredExpenses.length !== 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                exportToExcell({
                  data: store.toJsonCSV,
                  fileName: 'GastosDiarios.xls'
                });
              }}
              startIcon={<AddCircleIcon />}
              sx={styles.buttonsMargin}
            >
              Exportar
            </Button>
          )}
          <Tooltip
            title={
              store.isModified
                ? 'Guarda los datos antes de importar'
                : 'Selecciona un archivo .xls o .xlsx para importar (Probado con BBVA)'
            }
            placement="top"
            arrow
          >
            <label>
              <input
                type="file"
                accept=".xls, .xlsx"
                onChange={handleSelectionFile}
                style={{ display: 'none' }}
                disabled={store.isModified}
              />
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<AddCircleIcon />}
                disabled={store.isModified}
                sx={{ margin: theme.spacing(1) }}
              >
                Importar
              </Button>
            </label>
          </Tooltip>
        </Box>
        <Box sx={styles.yearsContainer}>
          {getAvailableYears().map((year) => (
            <Button
              key={year}
              variant="primarySpacing"
              onClick={() => {
                setSelectedYear(year);
                filterExpensesByMonthAndYear(selectedMonth, year);
              }}
              sx={selectedYear === year ? styles.primaryButtonSelected : {}}
            >
              {year}
            </Button>
          ))}
        </Box>
        <Box sx={styles.monthsContainer}>
          {months.map((month, index) => (
            <Tooltip
              key={month}
              title={
                store.isModified && selectedMonth !== index
                  ? 'Guarda los datos antes de cambiar de mes'
                  : 'Filtra por mes'
              }
              placement="top"
              arrow
            >
              <Box>
                <Button
                  key={month}
                  variant="contained"
                  color="secondary"
                  sx={
                    selectedMonth === index
                      ? styles.secondaryButtonSelected
                      : {}
                  }
                  style={{
                    margin: '0 2px',
                    textTransform: 'capitalize',
                    width: '100%'
                  }}
                  onClick={() => {
                    setSelectedMonth(index);
                    filterExpensesByMonthAndYear(index, currentYear);
                  }}
                  disabled={store.isModified && selectedMonth !== index}
                >
                  {month}
                </Button>
              </Box>
            </Tooltip>
          ))}
        </Box>
        <ResponsiveTable
          isLoading={store.isLoading}
          data={store.filterDailyExpensesMyMonthAndYear(
            selectedMonth,
            selectedYear
          )}
          onRowDelete={(row) => row.delete()}
          columns={[
            {
              label: 'Concepto',
              renderCell: (row: DailyExpense) => (
                <TextField
                  sx={styles.cell}
                  type="text"
                  value={row.concept}
                  onChange={(e) => row.setConcept(e.target.value)}
                  placeholder="Concepto"
                />
              )
            },
            {
              label: 'Importe',
              renderCell: (row: DailyExpense) => {
                return (
                  <TextField
                    sx={styles.cell}
                    type="text"
                    key={row._forceUpdate}
                    defaultValue={getFormattedFloatValue(row)}
                    onChange={(e) => handleImportFloatChange(e, row)}
                    onBlur={handleImportFloatBlur}
                    placeholder="0,00"
                    inputProps={{
                      inputMode: 'decimal'
                    }}
                  />
                );
              }
            },
            {
              label: 'Fecha',
              renderCell: (row: DailyExpense) => (
                // https://mui.com/x/react-date-pickers/date-picker/
                <DatePicker
                  sx={styles.cell}
                  format="dd/MM/yyyy"
                  value={new Date(row.date)}
                  onChange={(date) => date && row.setDate(date)}
                />
              )
            },
            {
              label: 'Categoría',
              renderCell: (row: DailyExpense) => (
                <Select
                  sx={styles.cell}
                  value={row.category.id}
                  onChange={(e) => {
                    const selectedCategory =
                      store.categories.find(
                        (cat) => cat.id === e.target.value
                      ) || row.category;

                    runInAction(() => {
                      row.setCategory(selectedCategory);
                    });
                  }}
                >
                  {store.categories.map((category: any) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              )
            },
            {
              label: 'Gasto / Ingreso',
              renderCell: (row: DailyExpense) => (
                <Select
                  sx={styles.cell}
                  value={row.isExpense.toString()}
                  onChange={(e) => {
                    runInAction(() => {
                      // Force update the row to refresh the cell
                      row._forceUpdate = Date.now();
                      row.setIsExpense(e.target.value === 'true');
                    });
                  }}
                >
                  <MenuItem value="true">Gasto</MenuItem>
                  <MenuItem value="false">Ingreso</MenuItem>
                </Select>
              )
            }
          ]}
        />
        <Box>
          <ShowSalaryMessage store={store} />
          {store.isModified && (
            <Alert severity="info" sx={styles.alert}>
              Se han detectado cambios, por favor, guarda los cambios antes de
              salir de la página.
            </Alert>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCreateDailyExpense()}
          startIcon={<AddCircleIcon />}
          sx={styles.buttonsMargin}
        >
          Añadir Fila
        </Button>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              await store.updateDailyExpenses();
              const availableYears = getAvailableYears();
              // If the selected year is not available anymore,
              // then select the first available year
              if (!availableYears.includes(selectedYear)) {
                setSelectedYear(availableYears[0] || currentYear);
                filterExpensesByMonthAndYear(
                  selectedMonth,
                  availableYears[0] || currentYear
                );
              }
            }}
            startIcon={<SaveIcon />}
            sx={styles.buttonsMargin}
          >
            Guardar
          </Button>
        </Box>
        <Box>
          <Box>
            <Typography variant="h6" style={styles.resultLabel}>
              Gasto / Ahorro total
            </Typography>
            <Typography variant="h5" style={styles.resultNumber}>
              {store
                .filterDailyExpensesMyMonthAndYear(selectedMonth, selectedYear)
                .filter((expense) => !expense.toDelete)
                .reduce((total, expense) => total + expense.cost, 0)
                .toLocaleString('es-ES', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              €
            </Typography>
          </Box>
        </Box>
      </Box>
      <ProfessionalTitle
        title="Gastos / Ingresos anuales"
        showBorderTop={true}
      />
      <Box sx={styles.container}>
        <Box sx={styles.yearsContainer}>
          {getAvailableYears().map((year) => (
            <Button
              key={year}
              variant="primarySpacing"
              onClick={() => {
                setSelectedYear(year);
              }}
              sx={selectedYear === year ? styles.primaryButtonSelected : {}}
            >
              {year}
            </Button>
          ))}
        </Box>
        <ResponsiveTable
          isLoading={store.isLoading}
          data={store.getMonthlyExpensesAndSavings(selectedYear)}
          columns={[
            {
              label: 'Mes',
              renderCell: (row: YearlyExpense) => (
                <Typography sx={{ textTransform: 'capitalize' }}>
                  {String(row.month)}
                </Typography>
              )
            },
            {
              label: 'Gastos',
              renderCell: (row: YearlyExpense) => (
                <Typography>
                  {row.expenses.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                  €
                </Typography>
              )
            },
            {
              label: 'Ingresos',
              renderCell: (row: YearlyExpense) => (
                <Typography>
                  {row.income.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                  €
                </Typography>
              )
            },
            {
              label: 'Ahorro',
              renderCell: (row: YearlyExpense) => (
                <Typography>
                  {row.savings.toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                  €
                </Typography>
              )
            }
          ]}
        />
      </Box>
      <ModalConfirmation
        fileData={fileData}
        setFileData={setFileData}
        store={store}
        handleDataStoreImport={handleDataStoreImport}
      />
    </Box>
  );
});

export default DailyExpenses;
