import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  SxProps,
  Theme,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {
  Savings as SavingsIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon
} from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { isUserLoggedIn } from '../core/lib/helper';
import { observer } from 'mobx-react';
import { RootStoreContext } from '../core/states/RootStore';
import MenuItem from './MenuItem';

const menuItems = [
  { label: 'Calculadora de sueldo', to: '/calculadora-sueldo' },
  { label: 'Calculadora de hipoteca', to: '/calculadora-hipoteca' },
  { label: 'Gastos diarios', to: '/gastos-personales' },
  { label: 'Gastos recurrentes', to: '/gastos-recurrentes' },
  { label: 'Metas', to: '/metas-personales' }
];

const Menu = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();

  const styles = {
    logoLink: {
      textDecoration: 'none',
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    logoIcon: {
      marginRight: '1px',
      marginBottom: '8px',
      color: theme.palette.secondary.dark,
      textShadow: '3px 3px 2px rgba(0, 0, 0, 0.1)'
    },
    logoText: {
      fontWeight: 'bold',
      marginBottom: '6px',
      letterSpacing: 1.2,
      color: theme.palette.secondary.dark,
      textShadow: '3px 3px 2px rgba(0, 0, 0, 0.1)'
    },
    menuDesktop: {
      flexGrow: 1,
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('lg')]: {
        display: 'flex'
      }
    },
    drawerContent: {
      position: 'relative',
      width: '250px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1)
    },
    mobileButtons: {
      textAlign: 'center',
      marginTop: theme.spacing(1)
    },
    navbarContainer: {
      background: `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`
    },
    menuMobileIcon: {
      marginBottom: '10px',
      color: theme.palette.secondary.dark
    },
    accountCircleIcon: {
      fontSize: '20px',
      marginRight: '3px'
    },
    menuDesktopItem: {
      marginRight: theme.spacing(1),
      textTransform: 'capitalize'
    },
    menuDesktopSessionButton: {
      marginLeft: 'auto'
    },
    menuMobileSessionButton: {
      marginTop: theme.spacing(1)
    }
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      const token = rootStore.token;
      const logged = await isUserLoggedIn(token);
      setUserLoggedIn(logged);
    };
    checkUserLoggedIn();
  }, [rootStore.token]);

  const Logo = () => (
    <Box component={RouterLink} to="/" style={styles.logoLink}>
      <SavingsIcon sx={styles.logoIcon} />
      <Typography variant="logo" style={styles.logoText}>
        Gestión de ahorro
      </Typography>
    </Box>
  );

  const SessionButton = ({
    userLoggedIn,
    sx
  }: {
    userLoggedIn: boolean;
    sx?: SxProps<Theme> | undefined;
  }) => (
    <MenuItem
      component={RouterLink}
      to={userLoggedIn ? '/logout' : '/login'}
      sx={{
        border: '1px solid #fff',
        borderRadius: '5px',
        ...sx
      }}
    >
      <AccountCircleIcon sx={styles.accountCircleIcon} />{' '}
      {userLoggedIn ? 'Salir' : 'Iniciar sesión'}
    </MenuItem>
  );

  return (
    <AppBar position="static" sx={styles.navbarContainer}>
      {/* Desktop menu */}
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          style={{ display: 'block' }}
        >
          <MenuIcon sx={styles.menuMobileIcon} />
        </IconButton>
        <Logo />
        <Box component="nav" sx={styles.menuDesktop}>
          {menuItems.map((item) => (
            <MenuItem
              key={item.to}
              component={RouterLink}
              to={item.to}
              isHoverDefaultContrast={false} // Changes hover color
              sx={styles.menuDesktopItem}
            >
              {item.label}
            </MenuItem>
          ))}
          <SessionButton
            userLoggedIn={userLoggedIn}
            sx={styles.menuDesktopSessionButton}
          />
        </Box>
      </Toolbar>
      {/* Mobile menu */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={styles.drawerContent}>
          <IconButton
            onClick={toggleDrawer(false)}
            aria-label="close"
            sx={styles.closeButton}
          >
            <CloseIcon />
          </IconButton>
          <Logo />
          {menuItems.map((item) => (
            <MenuItem
              key={item.to}
              component={RouterLink}
              to={item.to}
              onClick={toggleDrawer(false)}
              sx={styles.mobileButtons}
            >
              {item.label}
            </MenuItem>
          ))}
          <SessionButton
            userLoggedIn={userLoggedIn}
            sx={styles.menuMobileSessionButton}
          />
        </Box>
      </Drawer>
    </AppBar>
  );
});

export default Menu;
