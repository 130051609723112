import axios from 'axios';
import { DailyExpense } from '../../personalExpenses/states/DailyExpensesStore';
import { RecurringExpense } from '../../personalExpenses/states/RecurringExpensesStore';

export const getFullUrl = () => {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port;
  const domain = port ? `${hostname}:${port}` : hostname;
  const fullUrl = `${protocol}//${domain}`;
  return fullUrl;
};

export const logoutUser = () => {
  // TODO: Maybe we can use the mobxstore to save the state of the login
  localStorage.removeItem('token');

  // Redirect to login page
  // window.location.href = '/login';
};

export const isUserLoggedIn = async (token) => {
  const fullUrl = getFullUrl();
  let isLoggedIn = false;
  if (token) {
    let response: any = undefined;
    try {
      response = await axios.get(`${fullUrl}/api/common/verifyToken`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response?.data?.verified) {
        isLoggedIn = true;
      }
    } catch (error) {
      isLoggedIn = false;
    }
  } else {
    isLoggedIn = false;
  }
  return isLoggedIn;
};

// Update both the real numeric value with dot as decimal
// separator and the value shown on the text field with
// a comma as decimal separator
export const handleCommaFloatInputChange = (e, setterUI, setter) => {
  let value = e.target.value;

  // Only numbers and commans are allowed
  value = value.replace(/[^0-9,]/g, '');

  setterUI(value);

  // Convert from comma to dot as a decimal separator
  if (value !== '' && value !== ',') {
    const parsedValue = parseFloat(value.replace(',', '.'));
    if (!isNaN(parsedValue)) {
      setter(parsedValue);
    }
  } else {
    setter(0);
  }
};

//Prevent that users type float numbers
export const preventInvalidInput = (e) => {
  if (e.data && /\D/.test(e.data)) {
    e.preventDefault();
  }
};

export const handleImportFloatChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  row: DailyExpense | RecurringExpense
) => {
  let value = e.target.value;

  // Only numbers, commas and dots are allowed
  value = value.replace(/[^0-9,-]/g, '');

  // Lets make sure the negative sign is at the beginning
  if (value.includes('-') && value.indexOf('-') > 0) {
    value = value.replace('-', '');
  }

  // Only floats with dot as decimal separator are allowed
  // when storing on the mobx store
  const parsedValue = parseFloat(value.replace(',', '.'));
  if (!isNaN(parsedValue)) {
    row.setCost(parsedValue);
  }

  // Update the value on the input field
  e.target.value = value;
};

export const handleImportFloatBlur = (
  e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  const parsedValue = parseFloat(e.target.value.replace(',', '.')) || 0;

  // When the user leaves the input field, format the value
  e.target.value = parsedValue.toLocaleString('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const getFormattedFloatValue = (
  row: DailyExpense | RecurringExpense
) => {
  // Show the correct format on the input field
  return row.cost.toLocaleString('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};
